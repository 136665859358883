/* eslint-disable prefer-const */
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import urljoin from "url-join";
import { Footer } from "./footer";
import { Header } from "./header";
import { Home } from "./home";
import { SignUp } from "./auth/sign-up";
import { SignIn } from "./auth/sign-in";
import { RegistrationComplete } from "./auth/registration-complete";
import { ForgotPassword } from "./auth/forgot-password";
import { Terms } from "./terms";
import { PrivacyPolicy } from "./privacy";
import { Cookies } from "./cookies";
import { Blog } from "./blog";
import { About } from "./about";
import { GlobalStyle } from "../../services/styled-components/global-styles";
import { GeneralInfromationContextProvider } from "components/general-information-context-provider";
import { ContentWrapper } from "../../styled/layouts";
import { GoToTopButton } from "../../components/go-to-top-button";
import { ScrollToTop } from "components/scroll-to-top";
import { ThemeProvider } from "components/theme/theme-provider";
import { ToastContainer } from "react-toastify";
import { RootHelmetAndScriptsBodyInjector } from "components/root-helmet";
import { useTranslation } from "react-i18next";
import { NotFound } from "./not-found";
import { PageRoute } from "components/check-roles/guard";
import { NewPassword } from "./auth/new-password";

interface IProps {
    url: string;
}

export const metaData = {
    ShortCompanyName: "BizBet",
    CompanyDomen: "bizbet.partners",
    CompanyName: "BizBet Partners",
};

export const Landing57 = ({ url }: IProps) => {
    const [__] = useTranslation();
    const { pathname } = useLocation();
    let redirectUrl = "/:url/*(/+)";
    if (url === "/") {
        redirectUrl = "/:url*(/+)";
    }

    return (
        <GeneralInfromationContextProvider>
            <ScrollToTop>
                <ThemeProvider>
                    <ContentWrapper>
                        <ToastContainer
                            position="top-center"
                            newestOnTop={true}
                        />
                        <RootHelmetAndScriptsBodyInjector
                            titleText={format(
                                __(
                                    "Партнерская программа {ShortCompanyName} - Партнерская программа для ставок на спорт, казино - {CompanyName}",
                                ),
                                metaData,
                            )}
                            metaText={format(
                                __(
                                    "Партнерская программа {CompanyDomen} для ставок на спорт, казино, CPA партнерка ➤ Высокие конверсии ➤ Пожизненная комиссия до 40% ➤ Еженедельные выплаты",
                                ),
                                metaData,
                            )}
                        />
                        <GlobalStyle />
                        <GoToTopButton />
                        <Header />
                        <Switch>
                            <Redirect
                                from={redirectUrl}
                                to={{
                                    pathname: pathname.slice(0, -1),
                                    state: { status: 301 },
                                }}
                            />
                            <Route
                                path={urljoin(url, "sign-up")}
                                exact
                                component={SignUp}
                            />
                            <Route
                                path={urljoin(
                                    url,
                                    "sign-in",
                                    "partner",
                                    ":partnerId",
                                )}
                                component={SignIn}
                            />
                            <Route
                                path={urljoin(url, "sign-in")}
                                exact
                                component={SignIn}
                            />
                            <Route
                                path={urljoin(url, "sign-up-complete")}
                                exact
                                component={RegistrationComplete}
                            />
                            <Route
                                path={urljoin(url, "forgot-password")}
                                exact
                                component={ForgotPassword}
                            />
                            <Route
                                path={urljoin(url, "terms")}
                                exact
                                component={Terms}
                            />
                            <Route
                                path={urljoin(url, "privacy")}
                                exact
                                component={PrivacyPolicy}
                            />
                            <Route
                                path={urljoin(url, "cookies")}
                                exact
                                component={Cookies}
                            />
                            <Route
                                path={urljoin(url, "blog")}
                                component={Blog}
                            />
                            <Route
                                path={urljoin(url, "about")}
                                exact
                                component={About}
                            />
                            <Route path={urljoin(url)} exact component={Home} />
                            <PageRoute
                                path={urljoin(url, "changepassword")}
                                component={NewPassword}
                            />
                            <Route path="*" component={NotFound} />
                        </Switch>
                    </ContentWrapper>
                    <Footer />
                </ThemeProvider>
            </ScrollToTop>
        </GeneralInfromationContextProvider>
    );
};
