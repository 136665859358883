import { Title } from "../../../../styled/layouts";
import {
    BlockWrapper,
    ItemImg,
    ItemTitle,
    ItemText,
    ItemBlock,
    InfoItem,
    ItemWrapper,
    ItemWrapperMob,
    FirstInfo,
    LinkFeedback,
} from "./styled";
import biglibizden from "../../../../assets/images/feedback/biglibizden.png";
import forumalnko from "../../../../assets/images/feedback/forumalnko.png";
import gamesliga from "../../../../assets/images/feedback/gamesliga.png";
import medusa from "../../../../assets/images/feedback/medusa.png";
import napolyon from "../../../../assets/images/feedback/napolyon.png";
import sbv from "../../../../assets/images/feedback/sbv.png";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import { ArrowIosForwardOutline } from "@styled-icons/evaicons-outline/ArrowIosForwardOutline";
import { ArrowIosBackOutline } from "@styled-icons/evaicons-outline/ArrowIosBackOutline";

export const Feedback = () => {
    const [__] = useTranslation();
    const settings = {
        dots: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: "560px",
        buttons: true,
        nextArrow: <ArrowIosForwardOutline size="18" />,
        prevArrow: <ArrowIosBackOutline size="18" />,
        responsive: [
            {
                breakpoint: 1699,
                settings: {
                    centerMode: false,
                    centerPadding: "0px",
                },
            },
        ],
    };
    const feedbacks = [
        {
            src: biglibizden,
            name: "bilgibizden",
            link: "https://www.bilgibizden10.com/",
            text: format(
                __(
                    "Мы хотели бы поблагодарить {CompanyName}, одну из немногих компаний, которая не доставляет никаких проблем нашим участникам.",
                ),

                { CompanyName: "BizBet" },
            ),
        },
        {
            src: forumalnko,
            name: "Forumbanko365",
            link: "https://www.forumbanko35.com/",
            text: format(
                __(
                    "Прежде всего, мы хотели бы поздравить вас, нашего ценного бизнес-партнера {CompanyName}, с искренними пожеланиями от всей семьи ForumBanko. Каждый момент, когда мы работаем с вами, доставляет нам удовольствие и радует наших участников. Будь то вы или ваши коллеги, работающие за кулисами, вы всегда помогаете нам во всех вопросах.",
                ),
                { CompanyName: "BizBet" },
            ),
        },
        {
            src: gamesliga,
            name: "Games Liga",
            link: "https://t.me/+3Xt6fJJVtOE1MGZk",
            text: format(
                __(
                    "Я очень доволен нашим сотрудничеством с {CompanyName}. Мои члены также очень довольны. Подход и обслуживание сотрудников службы поддержки очень положительные. Выплаты никогда не задерживаются. Мы сотрудничаем с {CompanyName} практически с момента его появления, и до сих пор у нас не возникало никаких проблем.",
                ),
                { CompanyName: "BizBet" },
            ),
        },
        {
            src: medusa,
            name: "Casino Medusa",
            link: "https://www.twitch.tv/casinomedusatv",
            text: format(
                __(
                    "Я очень доволен партнерской программой {CompanyName}. Комиссионные ставки действительно хорошие, а партнерская программа становится еще лучше благодаря талантливым, вежливым и дружелюбным менеджерам, которые отлично выполняют свою работу. Я верю, что с каждым днем мы будем становиться все лучше и лучше вместе.",
                ),
                { CompanyName: "BizBet" },
            ),
        },
        {
            src: sbv,
            name: "SURE Betting",
            text: format(
                __(
                    "До начала работы в {CompanyName} у меня был опыт работы в этом секторе. Я был знаком с тонкостями и сложностями этой индустрии. Но компания {CompanyName} сделала все, чтобы нам было удобнее и выгоднее, благодаря удобным акциям и множеству различных интересных материалов и событий. Я хотел бы поблагодарить компанию {CompanyName} за то, что она предоставляет мне возможность регулярно зарабатывать каждый месяц, а моего менеджера по работе с партнерами - за то, что он помогает мне в любой ситуации, когда я застреваю.",
                ),
                { CompanyName: "BizBet" },
            ),
        },
        {
            src: napolyon,
            name: "Napolyon",
            link: "https://www.youtube.com/channel/UCJJmsmayqVgXjd87ZP6AQ9w",
            text: format(
                __(
                    "Перед началом работы с {CompanyName} у нас были некоторые вопросы. Получим ли мы необходимую поддержку? Будем ли мы получать выплаты вовремя? Но потом мы увидели, что на сайте много новшеств и разработок. Выплаты производятся вовремя. Мы также прилагаем усилия для улучшения нашей платформы, отправляя смс и электронные письма нашим игрокам. Спасибо {CompanyName}",
                ),
                { CompanyName: "BizBet" },
            ),
        },
    ];

    return (
        <BlockWrapper id="feedbacks">
            <Title color="#fff">{__("Отзывы")}</Title>
            <ItemBlock>
                <Slider {...settings}>
                    {feedbacks.map((feedback, index) => (
                        <>
                            <ItemWrapper key={`${feedback.name}_${index}`}>
                                <LinkFeedback
                                    href={feedback.link}
                                    target="__blank"
                                >
                                    <ItemImg src={feedback.src} />
                                    <InfoItem>
                                        <ItemTitle>{feedback.name}</ItemTitle>
                                        <ItemText>{feedback.text}</ItemText>
                                    </InfoItem>
                                </LinkFeedback>
                            </ItemWrapper>
                            <ItemWrapperMob>
                                <LinkFeedback
                                    href={feedback.link}
                                    target="__blank"
                                >
                                    <FirstInfo>
                                        <ItemImg src={feedback.src} />
                                        <InfoItem>
                                            <ItemTitle>
                                                {feedback.name}
                                            </ItemTitle>
                                        </InfoItem>
                                    </FirstInfo>
                                    <ItemText>{feedback.text}</ItemText>
                                </LinkFeedback>
                            </ItemWrapperMob>
                        </>
                    ))}
                </Slider>
            </ItemBlock>
        </BlockWrapper>
    );
};
