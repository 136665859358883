import { useTranslation } from "react-i18next";
import { useDefinedContext } from "hooks/use-context-exist";
import { PartnersProgramGeneralInformationContext } from "views";
import { AndroidImg } from "../../../components/icons/android";
import logoSrc from "../../../assets/images/logo/logo.png";
import { ContactBlock } from "../../../components/contacts";
import {
    Block,
    FooterElement,
    Logo,
    ColumnHeader,
} from "../../../styled/layouts";
import { Wrapper, Copyright, Android, AndroidLogo } from "./styled";

export const Footer = () => {
    const [__] = useTranslation();
    const { agentSiteInformation, androidAppLinks } = useDefinedContext(
        PartnersProgramGeneralInformationContext,
    );
    const date = new Date().getFullYear();
    return (
        <Wrapper id="contacts">
            <Block allowInMobile>
                <Logo to="/">
                    <img src={logoSrc} alt="logo" />
                </Logo>
                <Copyright>
                    Copyright © {date} «{agentSiteInformation.companyName}»
                    <br />
                    {__("Все права защищены")}.
                </Copyright>
            </Block>
            <Block forbiddenInDesktop allowInMobile>
                {!!androidAppLinks && (
                    <>
                        <ColumnHeader align="left">
                            {__("Наше приложение")}
                        </ColumnHeader>
                        <Android href={androidAppLinks} target="_blank">
                            <AndroidLogo>
                                <AndroidImg />
                            </AndroidLogo>
                            {__("Android")}
                        </Android>
                    </>
                )}
            </Block>
            <Block>
                <FooterElement to="privacy">
                    {__("Политика конфиденциальности")}
                </FooterElement>
                <FooterElement to="terms">
                    {__("Условия сотрудничества")}
                </FooterElement>
                <FooterElement to="cookies">{__("Cookie")}</FooterElement>
            </Block>
            <ContactBlock align={"left"} />
            <Block>
                {!!androidAppLinks && (
                    <>
                        <ColumnHeader align="left">
                            {__("Наше приложение")}
                        </ColumnHeader>
                        <Android href={androidAppLinks} target="_blank">
                            <AndroidLogo>
                                <AndroidImg />
                            </AndroidLogo>
                            {__("Android")}
                        </Android>
                    </>
                )}
            </Block>
        </Wrapper>
    );
};
