import { AboutWrapper, Title, TitleImg, TitleBlock } from "./styled";
import { Slider } from "./slider";
import ExploreImg from "../../../assets/images/explore.svg";
import { useTranslation } from "react-i18next";
import { RootHelmetAndScriptsBodyInjector } from "components/root-helmet";
import { metaData } from "../index";

export const About = () => {
    const [__] = useTranslation();
    const companyName = "Bizbet";
    return (
        <>
            <RootHelmetAndScriptsBodyInjector
                titleText={format(
                    __("О нас - партнерская программа {CompanyName}"),
                    metaData,
                )}
                metaText={format(
                    __(
                        "Партнерская программа {CompanyName} - это выгодный и простой способ заработка на партнерском маркетинге в сфере онлайн-игр и ставок. Узнайте больше о нашей компании и возможностях партнерства",
                    ),
                    metaData,
                )}
            />
            <TitleBlock>
                <TitleImg src={ExploreImg} alt="bizbet" role="img" />
                <Title>{__("Новый волнующий мир ставок")}</Title>
            </TitleBlock>

            <AboutWrapper>
                <h4>
                    {format(__("{companyName} — новый волнующий мир ставок"), {
                        companyName,
                    })}
                </h4>
                <b>
                    {format(
                        __(
                            "{companyName} — это новый букмекер на рынке, который стремится обеспечить турецких любителей азартных игр и спорта обслуживанием непревзойденного качества. Платформа {companyName} предлагает современный интерфейс, удобную навигацию, высокие коэффициенты и тысячи маркетов по самым разным видам спорта.",
                        ),
                        { companyName },
                    )}
                </b>
                <p>
                    {format(
                        __(
                            "Выбирая {companyName}, игроки также получают доступ к широкому выбору онлайн-слотов от ведущих поставщиков, ставкам на киберспорт и новейшим играм казино. Настольная и мобильная версия платформы обеспечивают превосходный пользовательский опыт нашим клиентам в Турции, которые хотят поддержать свои любимые команды и хорошо провести время, делая ставки на спорт.",
                        ),
                        { companyName },
                    )}
                </p>
                <h4>
                    {format(
                        __(
                            "Что делает {companyName} Partners лучшей партнерской программой?",
                        ),
                        { companyName },
                    )}
                </h4>
                <p>
                    {format(
                        __(
                            "У вас возникло желание стать участником партнерской программы {companyName}, но сначала вам хотелось бы узнать больше подробностей о том, как она устроена и какие преимущества вы получаете? Для вашего удобства мы подготовили список главных причин, по которым {companyName} Partners станет превосходным выбором для партнеров:",
                        ),
                        { companyName },
                    )}
                </p>
                <ul>
                    <li>
                        {__(
                            "Высокий коэффициент конверсии и конкурентный уровень комиссии",
                        )}
                    </li>
                    <li>
                        {__(
                            "Доступ к регулярно обновляемым маркетинговым материалам",
                        )}
                    </li>
                    <li>{__("Автоматические платежи по требованию")}</li>
                    <li>{__("Различные способы оплаты")}</li>
                    <li>
                        {__(
                            "Персональная зона и панель пользователя с обновляемой информацией",
                        )}
                    </li>
                    <li>{__("Личный помощник")}</li>
                    <li>{__("Рекомендации по маркетинговым стратегиям")}</li>
                </ul>
                <h4>
                    {format(
                        __("Как стать участником {companyName} Partners?"),
                        {
                            companyName,
                        },
                    )}
                </h4>
                <p>
                    {format(
                        __(
                            "Процесс регистрации, который необходимо пройти, чтобы стать участником {companyName} Partners, очень прост и состоит из трех простых шагов. Давайте подробно остановимся на каждом шаге, чтобы вы получили исчерпывающую информацию о том, как стать членом нашей замечательной партнерской программы:",
                        ),
                        { companyName },
                    )}
                </p>
                <ol>
                    <li>
                        {format(
                            __(
                                "Откройте сайт {companyName} Partners и нажмите на кнопку «РЕГИСТРАЦИЯ».",
                            ),
                            { companyName },
                        )}
                    </li>
                    <li>
                        {__(
                            "Заполните поля с личными данными и информацией о веб-сайте (имя пользователя, пароль, URL и категория сайта, язык, полное имя, номер телефона, предпочтительный способ оплаты и т. д.).",
                        )}
                    </li>
                    <li>
                        {__(
                            "Установите флажок в поле напротив Условий, тем самым согласившись с ними, после чего еще раз нажмите на кнопку «РЕГИСТРАЦИЯ» для завершения процесса.",
                        )}
                    </li>
                </ol>
                <h4>
                    {__(
                        "Как добиться увеличения трафика в букмекерской индустрии",
                    )}
                </h4>
                <p>
                    {__(
                        "Первое, что необходимо сделать для генерирования и монетизации хорошего трафика в ставках на спорт, это выбрать партнерскую программу. Затем вашей задачей будет увеличить трафик, используя следующие советы и стратегии:",
                    )}
                </p>
                <Slider />
                <h4>
                    {format(
                        __("Как устроена партнерская программа {companyName}?"),
                        { companyName },
                    )}
                </h4>
                <p>
                    {format(
                        __(
                            "Сотрудничать с партнерской программой {companyName} очень просто. После регистрации аккаунта вы получите уникальный идентификационный номер. Используя этот номер, вы можете начать перенаправлять трафик на {companyName} и получать компенсацию за каждую конверсию. За регистрацию аккаунта не нужно платить, и вы получаете доступ к качественным маркетинговым материалам и пользовательской панелей с информацией о клиентах, отчетами и аналитикой.",
                        ),
                        { companyName },
                    )}
                </p>
                <h4>{__("Каналы привлечения клиентов")}</h4>
                <p>
                    {format(
                        __(
                            "Партнерская программа {companyName} позволяет вам выбрать среди различных каналов привлечения клиентов для вашего партнерского бизнеса. Вам вовсе не обязательно иметь веб-сайт, чтобы начать рекомендовать игроков. Вы можете выбрать среди следующих каналов привлечения клиентов или сочетать их для наилучшего результата:",
                        ),
                        { companyName },
                    )}
                </p>
                <ul>
                    <li>{__("Веб-сайт")}</li>
                    <li>{__("Социальные сети (TikTok, Facebook и т. д.)")}</li>
                    <li>{__("Мессенджеры (например, Telegram)")}</li>
                    <li>{__("YouTube")}</li>
                    <li>{__("SMS/электронанная почта")}</li>
                    <li>{__("PPC-кампании")}</li>
                </ul>
                <p>
                    {__(
                        "Не забывайте использовать ваш уникальный идентификационный номер, чтобы все рекомендованные вами игроки были связаны с вашим партнерским аккаунтом. Тогда вы сможете получать адекватную компенсацию за каждую конверсию.",
                    )}
                </p>
                <h4>
                    {__("Как зарабатывать с помощью партнерских программ?")}
                </h4>
                <p>
                    {format(
                        __(
                            "Поскольку программы партнерского маркетинга входят в число наиболее популярных способов для зарабатывания денег онлайн, ими неизменно пользуются веб-мастеры, владельцы популярных страниц в социальных сетях и владельцы сайтов. Ставки — одна из самых прибыльных индустрий для партнерского маркетинга, и устроено все следующим образом. {companyName} предлагает вам, наравне с тысячами других партнеров, прекрасные условия для участия в нашей партнерской программе. Перенаправляя своих пользователей на {companyName}, вы получаете комиссии за каждого пополняющего счет игрока и высокий коэффициент конверсии. Партнерская программа и все ее участники крайне важны для {companyName}, поскольку необходимость взаимовыгодных партнерств входит в число базовых убеждений компании.",
                        ),
                        { companyName },
                    )}
                </p>
                <h4>{__("Как производится вывод средств?")}</h4>
                <p>
                    {format(
                        __(
                            "Существует несколько способов вывода средств, заработанных в рамках партнерской программы {companyName}. Вы можете выбрать способ оплаты с наиболее выгодными условиями и затем использовать его для совершения онлайн-платежей или снятия наличных в банкомате. Комиссия при выводе средств не взимается, и вы всегда сможете найти способ для снятия денег, отвечающий вашим требованиям.",
                        ),
                        { companyName },
                    )}
                </p>
            </AboutWrapper>
        </>
    );
};
