import { media } from "services/styled-components/variables";
import styled from "styled-components";
import { gradientColor } from "../../services/styled-components/variables";
import {
    backgroundColor,
    gradientBackgraund,
} from "../../services/styled-components/variables";

interface WrapperProps {
    visible: boolean;
    isLightBackground: boolean;
}

export const WrapperSC = styled.div<WrapperProps>`
    display: ${(props: WrapperProps) => {
        return props.visible ? "flex" : "none";
    }};
    z-index: 3;
    position: fixed;
    background: ${props =>
        props.isLightBackground ? backgroundColor : gradientBackgraund};
    right: 6rem;
    bottom: 3rem;
    border-radius: 50%;
    cursor: pointer;
    width: 6rem;
    height: 6rem;
    transition: all 0.2s;
    align-items: center;
    justify-content: center;

    ${media.mdMax`
        width: 4rem;
        height: 4rem;
        right: 1rem;
        bottom: 1rem;
    `}

    @media (hover: none) {
        &:active {
            background: ${gradientColor};
        }
    }

    @media (hover: hover) {
        &:hover {
            background: ${gradientColor};
        }
    }
`;

export const ArrowImageSC = styled.img`
    width: 4rem;
    height: 4rem;

    ${media.mdMax`
        width: 0.9rem;
        height:1.4rem;
    `}
`;
