import { ContentLoader } from "components/components-common/content-loader";
import { List } from "react-content-loader";
import { useTermsAndConditions } from "hooks/query/use-terms-and-conditions";

import { useTranslation } from "react-i18next";
import { ArticleTitle, ArticleWrapper } from "../../../styled/layouts";
import { RootHelmetAndScriptsBodyInjector } from "components/root-helmet";
import { metaData } from "../index";

export const Terms = () => {
    const { text, loading } = useTermsAndConditions();
    const [__] = useTranslation();

    return (
        <>
            <RootHelmetAndScriptsBodyInjector
                titleText={format(
                    __("Условия сотрудничества с {CompanyName}"),
                    metaData,
                )}
                metaText={format(
                    __(
                        "Ознакомьтесь с условиями сотрудничества с {CompanyName} - партнерской программой компании {ShortCompanyName}. Узнайте, как зарабатывать с нами и какие преимущества предоставляем нашим партнерам",
                    ),
                    metaData,
                )}
            />
            <ArticleTitle>{__("Правила и условия")}</ArticleTitle>
            <ContentLoader component={List} times={10} loading={loading}>
                <ArticleWrapper
                    dangerouslySetInnerHTML={{
                        // eslint-disable-next-line @typescript-eslint/naming-convention
                        __html: text,
                    }}
                />
            </ContentLoader>
        </>
    );
};
