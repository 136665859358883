import { LocalizedNavLink, useLanguage } from "components/language-provider";
import React from "react";
import { useTranslation } from "react-i18next";
import { HashLink } from "react-router-hash-link";
import urljoin from "url-join";
import {
    Title,
    ButtonsBlock,
    Wrapper,
    TextBlock,
    RunningLineWrapper,
    RunningLine,
    RunningLineItem,
} from "./styled";

export const FirstScreen = () => {
    const [__] = useTranslation();
    const {
        language: { urlPrefix, shortName },
    } = useLanguage();
    const langCode = shortName.toLowerCase();

    const lines = [
        __("Высокая комиссия"),
        __("Лайфтайм выплаты"),
        __("Еженедельный вывод"),
        __("Персональная поддержка"),
        __("Суб-партнерская программа"),
        __("Конвертящие баннеры"),
        __("Высокая комиссия"),
        __("Лайфтайм выплаты"),
        __("Еженедельный вывод"),
        __("Персональная поддержка"),
        __("Суб-партнерская программа"),
        __("Конвертящие баннеры"),
    ];

    const [part1, part2] = __(
        "Инструкция использования{separator}партнёрской программы",
    ).split("{separator}");

    return (
        <>
            <Wrapper>
                <TextBlock>
                    <Title>
                        {format(__("Вы заработаете с {CompanyName}!"), {
                            CompanyName: "BizBet",
                        })}
                    </Title>
                    <ButtonsBlock>
                        <LocalizedNavLink to="/sign-up">
                            {__("Стать партнером")}
                        </LocalizedNavLink>
                        {["en", "pt", "tr"].includes(langCode) ? (
                            <a
                                href={`/assets/pdf/bizbet/${langCode}/presentation.pdf`}
                                target="_blank"
                                rel="noreferrer"
                                download="download"
                                className="downloadButton"
                            >
                                {part1}
                                <br />
                                {part2}
                            </a>
                        ) : (
                            <HashLink
                                smooth={true}
                                to={urljoin("/", urlPrefix, "/", "#whyItWorks")}
                                className="hashButton"
                            >
                                {__("Как это работает")}
                            </HashLink>
                        )}
                    </ButtonsBlock>
                </TextBlock>
            </Wrapper>
            <RunningLineWrapper>
                <RunningLine>
                    {lines.map((line, index) => (
                        <RunningLineItem key={index}>{line}</RunningLineItem>
                    ))}
                </RunningLine>
            </RunningLineWrapper>
        </>
    );
};
