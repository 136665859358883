import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Nav } from "./navigation";
import { HeaderWrapper, RegisterBtnDesktop, MobileMenuIcon } from "./styled";
import { LocalizedNavLink } from "components/language-provider";
import { MenuMobile } from "./navigation-mobile";
import { Action } from "redux";
import { connect } from "react-redux";
import { statesSelectors } from "services/redux/root/states/selectors";
import { StatesActionCreators } from "services/redux/root/states/actions";
import { Logo } from "../../../styled/layouts";
import { backgroundColor } from "../../../services/styled-components/variables";
import logoSrc from "../../../assets/images/logo/logo.png";
import logoDarkSrc from "../../../assets/images/logo/logo-dark.png";
interface IProps {
    isMenuBurgerClickedOnLanding: boolean;
    setIsMenuBurgerClickedOnLanding: (visibility: boolean) => Action;
}

export const Header = connect(
    state => ({
        isMenuBurgerClickedOnLanding:
            statesSelectors.getIsMenuBurgerClickedOnLanding(state),
    }),
    dispatch => ({
        setIsMenuBurgerClickedOnLanding: (visibility: boolean) =>
            dispatch(
                StatesActionCreators.setIsMenuBurgerClickedOnLanding(
                    visibility,
                ),
            ),
    }),
)(
    React.memo(
        ({
            isMenuBurgerClickedOnLanding,
            setIsMenuBurgerClickedOnLanding,
        }: IProps) => {
            const [__] = useTranslation();
            const [srclogo, setSrcLogo] = useState(logoSrc);
            const [bgcolor, setBgcolor] = useState(backgroundColor);
            const [color, setColor] = useState("#ffffff");

            useEffect(() => {
                const GetBlock: HTMLElement | null =
                    document.getElementById("faq");
                const GetFeedback: HTMLElement | null =
                    document.getElementById("feedbacks");

                if (isMenuBurgerClickedOnLanding) {
                    setSrcLogo(logoSrc);
                }
                // изменение цвета хедера при попадании на темные блоки
                const handleScroll = () => {
                    if (GetBlock && GetFeedback) {
                        const target = GetBlock.getBoundingClientRect().top;
                        const finishTarget =
                            GetBlock.getBoundingClientRect().bottom;
                        const target2 = GetFeedback.getBoundingClientRect().top;
                        const finishTarget2 =
                            GetFeedback.getBoundingClientRect().bottom;
                        if (
                            (target &&
                                target <= 0 &&
                                finishTarget &&
                                finishTarget >= 0) ||
                            (target2 &&
                                target2 <= 0 &&
                                finishTarget2 &&
                                finishTarget2 >= 0)
                        ) {
                            setSrcLogo(logoDarkSrc);
                            setBgcolor("#ffffff");
                            setColor(backgroundColor);
                        } else {
                            setSrcLogo(logoSrc);
                            setBgcolor(backgroundColor);
                            setColor("#ffffff");
                        }
                    }
                };
                document.addEventListener("scroll", handleScroll);
                return () => {
                    document.removeEventListener("scroll", handleScroll);
                };
            }, [isMenuBurgerClickedOnLanding]);
            const menuItemsData = [
                {
                    linkUrl: "/about",
                    linkText: __("О нас"),
                },
                {
                    linkUrl: "/terms",
                    linkText: __("Условия сотрудничества"),
                },
                {
                    linkUrl: "/blog",
                    linkText: __("Блог"),
                },
            ];

            const menuItemsMobData = [
                {
                    linkUrl: "/terms",
                    linkText: __("Условия сотрудничества"),
                },
                {
                    linkUrl: "/about",
                    linkText: __("О нас"),
                },
                {
                    linkUrl: "/blog",
                    linkText: __("Блог"),
                },
                {
                    linkUrl: "#faq",
                    linkText: __("FAQ"),
                },
                {
                    linkUrl: "/privacy",
                    linkText: __("Политика конфиденциальности"),
                },
                {
                    linkUrl: "/cookies",
                    linkText: __("Cookies"),
                },
            ];

            return (
                <HeaderWrapper bgcolor={bgcolor}>
                    <Logo to="/">
                        <img src={srclogo} alt="logo" />
                    </Logo>
                    <MenuMobile
                        onClick={() =>
                            setIsMenuBurgerClickedOnLanding(
                                !isMenuBurgerClickedOnLanding,
                            )
                        }
                        color={color}
                        isOpenedMobileMenu={isMenuBurgerClickedOnLanding}
                        menuItemsData={menuItemsMobData}
                    />
                    <Nav color={color} menuItemsData={menuItemsData} />
                    <RegisterBtnDesktop
                        onClick={() => setIsMenuBurgerClickedOnLanding(false)}
                    >
                        <LocalizedNavLink to="/sign-in">
                            {__("Вход")}
                        </LocalizedNavLink>
                        <LocalizedNavLink to="/sign-up">
                            {__("Регистрация")}
                        </LocalizedNavLink>
                    </RegisterBtnDesktop>
                    <MobileMenuIcon
                        color={color}
                        isOpenedMobileMenu={isMenuBurgerClickedOnLanding}
                        onClick={() =>
                            setIsMenuBurgerClickedOnLanding(
                                !isMenuBurgerClickedOnLanding,
                            )
                        }
                    >
                        <i></i>
                        <i></i>
                        <i></i>
                    </MobileMenuIcon>
                </HeaderWrapper>
            );
        },
    ),
);
