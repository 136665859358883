import styled from "styled-components";
import { media } from "services/styled-components/variables";
import { accentColor } from "../../../services/styled-components/variables";
import { StateOfBtn } from "../../../styled/layouts";

interface IHeaderWrapper {
    bgcolor: string;
}

interface IMobileMenuIcon {
    openMobileMenu?: boolean;
    isOpenedMobileMenu?: boolean;
}

export const HeaderWrapper = styled.div<IHeaderWrapper>`
    display: flex;
    position: fixed;
    justify-content: space-between;
    align-items: center;
    background-color: ${props => props.bgcolor};
    padding: 0 18.7%;
    gap: 4em;
    top: 0;
    width: 100%;
    height: 9.2rem;
    box-sizing: border-box;
    z-index: 2;

    @media screen and (max-width: 1800px) {
        justify-content: space-evenly;
        padding: 0 1%;
        gap: 0.5em;
    }

    ${media.mdMax`
        height: 5rem;
        padding: 0 1.6em;
    `}
`;

export const MobileMenuIcon = styled.div<IMobileMenuIcon>`
    display: none;
    ${media.mdMax`
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex-shrink: 0;
        cursor: pointer;
        position: relative;
        width: 1.8em;
        height: 1.4em;
        order: 2;
     `}

    & i {
        display: none;

        ${media.mdMax`
            display: block;
            overflow: hidden;
            height:1px;
            background: ${(props: { color: any }) => props.color};
            border-radius: 1px;
            transition: all 300ms ease 0ms;
        `}
    }
`;

export const RegisterBtnDesktop = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 2em;
    position: relative;
    z-index: 2;

    ${media.mdMax`
        gap: 1em;
    `}

    & a {
        font-size: 1.6em;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-weight: 700;
        color: ${accentColor};
        border: 1px solid ${accentColor};
        border-radius: 2.5em;
        padding: 0.625em 1.19em 0.75em;
        line-height: 1;
        ${StateOfBtn}

        &:hover, &:active {
            color: #fff;
        }

        ${media.mdMax`
            font-size: 1.3em;
            padding: 0.67em 1em;
            font-weight: 700;
        `}
    }

    & a:last-child {
        background: ${accentColor};
        color: #ffffff;
        border-radius: 2.5em;
        ${StateOfBtn}
    }
`;
