import styled from "styled-components";
import {
    accentColor,
    secondTextColor,
} from "../../../../services/styled-components/variables";
import peopleBg from "../../../../assets/images/people/people-bg.png";
import beforeIcon from "../../../../assets/images/people/before.svg";
import afterIcon from "../../../../assets/images/people/after.svg";
import { media } from "services/styled-components/variables";
import { Title } from "../../../../styled/layouts";

export const BlockWrapper = styled.div`
    background-image: url(${peopleBg});
    background-repeat: no-repeat;
    background-size: cover;
`;
export const MainBlock = styled.div`
    padding: 10rem 24.4rem 6.3rem;

    ${media.mdMax`
        padding: 5rem 1.6rem 4.6rem;
    `}

    ${Title} {
        margin-bottom: 5rem;

        ${media.mdMax`
            margin-bottom: 2rem;
        `}

        ${media.xsMax`
            max-width: 30rem;
        `}
    }
`;
export const PeopleBlock = styled.div`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 2rem;

    @media screen and (max-width: 1320px) {
        display: none;
    }
`;

export const PeopleMobBlock = styled.div`
    display: none;
    max-width: 45rem;
    margin: 0 auto;
    ${media.mdMax`
       display: block;
    `}

    @media screen and (max-width: 1320px) {
        display: block;
    }

    & .slick-arrow {
        color: ${accentColor};
        height: 3.5rem;
        width: 4rem;
        top: auto;
        bottom: -3rem;

        ${media.md`
            bottom: -4.5rem;
        `}
    }

    & .slick-dots li button {
        width: 1rem;
        height: 1rem;
        border: 0;
        background: rgba(41, 41, 45, 0.3);
    }

    & .slick-dots .slick-active button {
        background: ${accentColor};
    }

    & .slick-prev {
        left: -1.5rem;
    }

    & .slick-next {
        right: -1.5rem;
    }

    & .slick-slide > div {
        display: flex;
        justify-content: center;

        img {
            margin-bottom: 2rem;
        }
    }
`;

export const ItemWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: ${secondTextColor};
    text-align: center;
    max-width: 38.7rem;
    position: relative;
    margin-bottom: 6.4rem;

    @media screen and (max-width: 1320px) {
        margin-bottom: 2rem;
    }

    ${media.mdMax`
        max-width: 27rem;
    `}

    &::before {
        content: "";
        position: absolute;
        background: url(${beforeIcon}) 0% 0% / contain no-repeat;
        background-repeat: no-repeat;
        width: 20.4rem;
        height: 20.5rem;
        top: 0;
        left: 0;

        ${media.mdMax`
            width: 13.6rem;
            height: 13.6rem;
            top: 0.5rem;
        `}
    }
    &::after {
        content: "";
        background: url(${afterIcon}) 0% 0% / contain no-repeat;
        position: absolute;
        width: 15.6rem;
        height: 12.7rem;
        top: 0;
        left: 18.7rem;

        ${media.mdMax`
            width: 9.6rem;
            height: 6.6rem;
            top: 1rem;
            left: 15.7rem;
        `}
    }
`;
export const ItemImg = styled.img`
    width: 21.2rem;
    height: 21.2rem;
    border-radius: 50%;
    position: relative;
    z-index: 1;
    margin-bottom: 2rem;

    ${media.mdMax`
        width: 14.2rem;
        height: 14.2rem;
    `}
`;
export const ItemTitle = styled.h5`
    font-size: 2rem;
    line-height: 120%;
    margin: 0 0 1.2rem 0;

    ${media.mdMax`
        font-size: 1.6rem;
    `}
`;
export const ItemText = styled.p`
    font-size: 1.4rem;
    line-height: 160%;
    margin: 0;

    ${media.mdMax`
        font-size: 1.2rem;
    `}
`;
