import React, { useRef } from "react";
import { Stage } from "hooks/sign-up/use-on-submit";
import { useTranslation } from "react-i18next";
import {
    Pagination,
    RegistrationWrapper,
    RegistrationButton,
    Banner,
    Dote,
    Title,
    ButtonsContainer,
} from "./styled";
import { useStepData } from "./hooks/use-step-data";
import { RootHelmetAndScriptsBodyInjector } from "components/root-helmet";
import { metaData } from "../../../index";
import { ReCAPTCHA, TReCAPTCHA } from "components/recaptcha";

export interface ISignUpForm {
    setStage: React.Dispatch<React.SetStateAction<Stage>>;
}

export const SignUpForm = React.memo(({ setStage }: ISignUpForm) => {
    const numberOfDots = 3;
    const [__] = useTranslation();
    const recaptchaRef = useRef<TReCAPTCHA>(null);

    const { stepData, formik, handleNextStep, handlePrevStep, step } =
        useStepData({
            setStage,
            recaptchaRef,
        });

    return (
        <>
            <RootHelmetAndScriptsBodyInjector
                titleText={format(
                    __("Регистрация в партнерской программе {CompanyName}"),
                    metaData,
                )}
                metaText={format(
                    __(
                        "Присоединяйтесь к {CompanyName} - партнерской программе компании {ShortCompanyName}. Зарегистрируйтесь на нашем сайте и начните зарабатывать с нами уже сегодня!",
                    ),
                    metaData,
                )}
            />
            <RegistrationWrapper>
                <Banner step={step} src={stepData[step].bannerSrc} />
                <form onSubmit={formik.handleSubmit}>
                    <Title>{__("Регистрация")}</Title>
                    {stepData[step].component}
                    <Pagination>
                        <ul>
                            {Array(numberOfDots)
                                .fill(0)
                                .map((_, i) => (
                                    <li key={i}>
                                        <Dote isActiveDote={step === i} />
                                    </li>
                                ))}
                        </ul>
                    </Pagination>
                    <ButtonsContainer>
                        {step !== 0 && (
                            <RegistrationButton
                                onMouseDown={() => {
                                    handlePrevStep(step - 1);
                                    window.gtag?.(
                                        "event",
                                        `Клик по кнопке "back"(${step})`,
                                    );
                                }}
                            >
                                {__("Назад")}
                            </RegistrationButton>
                        )}
                        {step < numberOfDots - 1 && (
                            <RegistrationButton
                                onMouseDown={async () => {
                                    await handleNextStep(step + 1);
                                    window.gtag?.(
                                        "event",
                                        `Клик по кнопке "next"(${step + 1})`,
                                    );
                                }}
                            >
                                {__("Далее")}
                            </RegistrationButton>
                        )}
                        {step === numberOfDots - 1 && (
                            <RegistrationButton
                                as="button"
                                type="submit"
                                onClick={() => {
                                    window.gtag?.(
                                        "event",
                                        "Complete Registration",
                                    );
                                }}
                            >
                                {__("Завершить регистрацию")}
                            </RegistrationButton>
                        )}
                    </ButtonsContainer>
                </form>
                <ReCAPTCHA ref={recaptchaRef} size="invisible" />
            </RegistrationWrapper>
        </>
    );
});
