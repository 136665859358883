import styled from "styled-components";
import { media } from "services/styled-components/variables";
import { H1 } from "../forgot-password/styled";

export const NewPasswordTitle = styled(H1)`
    ${media.xl`
        max-width: 44.8rem;
        font-size: 4.8rem;
    `}
`;

export const NewPasswordImg = styled.img`
    position: absolute;
    left: 70rem;
    z-index: -1;
    width: 94rem;
    height: 61.4rem;

    ${media.xlMax`
        width: 65rem;
        right: 0;
        left: auto;
    `}

    ${media.lgMax`
        left: auto;
    `}
    
    ${media.mdMax`
        left: auto;
    `}
    
    ${media.smMax`
        top: auto;
        bottom: -10rem;
        width: 90vw;
        right: 50%;
        transform: translateX(50%);
    `}
`;
