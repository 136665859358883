import { useLazyQuery } from "@apollo/client";
import {
    CheckLoginFree,
    CheckLoginFreeVariables,
} from "gql/types/operation-result-types";
import CHECK_LOGIN_FREE from "./check-login-free.gql";
import { useTranslation } from "react-i18next";

export function useCheckLoginFree() {
    const [__] = useTranslation();
    const [getCheckLogin] = useLazyQuery<
        CheckLoginFree,
        CheckLoginFreeVariables
    >(CHECK_LOGIN_FREE);

    const checkLoginIsFree = async (login: string) => {
        const loginIsFree = await getCheckLogin({
            variables: {
                login,
            },
        });
        if (loginIsFree.data?.checkLogin === false) {
            return __(
                "Такое имя уже зарегистрировано. Попробуйте использовать другое.",
            );
        }
    };

    return checkLoginIsFree;
}
