import { useTranslation } from "react-i18next";
import {
    BackgroundWrapper,
    Container,
    SubTitle,
    StepBlock,
    Count,
    StepsWrapper,
    StepDescription,
    StepTitle,
    StepText,
    StepLink,
    GetMoneyBlock,
    GetMoneyTitle,
    GetMoneySubTitle,
    GetMoneyUnderlinedTitle,
    Icon,
    GetMoneyDescription,
    GetMoneyWrapper,
    GetMoneyItem,
    ExtraBackgroundWrapper,
    Strikethrough,
} from "./styled";
import { Title } from "../../../../styled/layouts";
import ballSrc from "../../../../assets/images/work/ball.svg";
import graphSrc from "../../../../assets/images/work/graph.svg";
import analyticsSrc from "../../../../assets/images/work/analytics.svg";
import integrationSrc from "../../../../assets/images/work/integration.svg";
import giftSrc from "../../../../assets/images/work/gift.svg";
import chatSrc from "../../../../assets/images/work/chat.svg";

export const WhyItWorks = () => {
    const [__] = useTranslation();

    const steps = [
        {
            title: __("Определитесь с источником трафика"),
            description: __(
                "Определите через какой ресурс вы будете приводить игроков и ",
            ),
            link: <StepLink to="/sign-up">{__("Зарегистрируйтесь")}</StepLink>,
        },
        {
            title: __("Разместите ссылку партнерской программы"),
            description: __(
                "Создайте партнерскую ссылку в аккаунте, разместите ее на своем ресурсе или раздайте друзьям",
            ),
        },
        {
            title: __("Получайте доход"),
            description: __(
                "Получайте доход от каждого юзера, который перешел по вашей ссылке ",
            ),
        },
    ];

    const whyItWorksItems = [
        {
            src: ballSrc,
            description: __("Сотрудничество по Спорт, Казино, Е-спорт трафику"),
        },
        {
            src: graphSrc,
            description: __("Прием трафика из апп, арбитража, ppc, сеток"),
        },
        {
            src: analyticsSrc,
            description: __("Детальную ежедневную аналитику"),
        },
        {
            src: giftSrc,
            description: format(
                __("Интеграцию продуктов {CompanyName} на сайт партнера"),
                { CompanyName: "BizBet" },
            ),
        },
        {
            src: integrationSrc,
            description: __("Специальные бонусы для игроков партнера"),
        },
        {
            src: chatSrc,
            description: __("Поддержку квалифицированного менеджера "),
        },
    ];

    return (
        <BackgroundWrapper id="whyItWorks">
            <ExtraBackgroundWrapper />
            <Container>
                <Title>{__("Как это работает")}</Title>
                <SubTitle>
                    <Strikethrough>
                        {__("Сложно, долго, непонятно")}
                    </Strikethrough>
                    {__("Всего 3 шага, чтобы начать зарабатывать:")}
                </SubTitle>
                <StepsWrapper>
                    {steps.map((step, index) => (
                        <StepBlock key={`${step.title}_${index}`}>
                            <Count>{index + 1}</Count>
                            <StepDescription>
                                <StepTitle>{step.title}</StepTitle>
                                <StepText>
                                    {step.description}
                                    {step.link}
                                </StepText>
                            </StepDescription>
                        </StepBlock>
                    ))}
                </StepsWrapper>
                <GetMoneyBlock>
                    <GetMoneyTitle>
                        <GetMoneySubTitle>{__("Чтобы вы ")}</GetMoneySubTitle>{" "}
                        <GetMoneySubTitle>
                            <GetMoneyUnderlinedTitle>
                                {__("гарантированно получали доход")}
                            </GetMoneyUnderlinedTitle>
                            ,
                        </GetMoneySubTitle>
                        <br />
                        <GetMoneySubTitle>
                            {__(" мы обеспечиваем:")}
                        </GetMoneySubTitle>
                    </GetMoneyTitle>
                    <GetMoneyWrapper>
                        {whyItWorksItems.map((whyItWorksItem, index) => (
                            <GetMoneyItem key={index}>
                                <Icon src={whyItWorksItem.src} />
                                <GetMoneyDescription>
                                    {whyItWorksItem.description}
                                </GetMoneyDescription>
                            </GetMoneyItem>
                        ))}
                    </GetMoneyWrapper>
                </GetMoneyBlock>
            </Container>
        </BackgroundWrapper>
    );
};
