import styled from "styled-components";
import { media } from "services/styled-components/variables";
import {
    accentColor,
    gradientBackgraund,
    secondTextColor,
    errorColor,
} from "../../services/styled-components/variables";
import { rtl } from "services/styled-components/rtl";

/* stylelint-disable */
interface IInputWrapper {
    forOther?: boolean;
}

export const InputWrapper = styled.div<IInputWrapper>`
    position: relative;
    height: 5rem;
    height: ${({ forOther }) => (forOther ? "12rem" : "5rem")};
    width: 38.7rem;
    ${media.mdMax`
        height: ${({ forOther }: IInputWrapper) =>
            forOther ? "10rem" : "4rem"};
        width: 28.8rem;
    `}
`;

export const Border = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: ${gradientBackgraund};
    display: none;
    border-radius: 3rem;
    z-index: -1;
`;

export const Required = styled.p`
    position: absolute;
    top: 1.2rem;
    font-size: 1.6rem;
    margin: 0;
    color: ${accentColor};
    ${rtl`left: 3rem`};
    ${media.mdMax`
        font-size: 1.4rem;
        top: 1rem;
        ${rtl`left: 2.4rem`};
    `}
`;

interface IProps {
    criticalityLevel?: number;
}

export const FormItemTextareaContent = styled.textarea<IProps>`
    position: relative;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 120%;
    color: #000000;
    outline: none;
    box-shadow: none;
    background: #ffffff;
    border-radius: 3rem;
    padding-left: 4rem;
    padding-top: 14px;
    padding-right: 14px;
    border: 1px solid #a7c3da;
    resize: none;
    scrollbar-width: thin;
    overflow: hidden;
    &::-webkit-scrollbar {
        width: 5px;
    }
    &::-webkit-scrollbar-track {
        background-color: #ffffff;
        -webkit-border-radius: 2px;
    }
    &::-webkit-scrollbar-thumb {
        background: #a7c3da;
        -webkit-border-radius: 2px;
    }
    border-color: ${({ criticalityLevel }) => {
        if (criticalityLevel === 1) {
            return "#e7a600";
        } else if (criticalityLevel === 2) {
            return errorColor;
        } else {
            return "#a7c3da";
        }
    }};
    ${media.mdMax`
        font-size: 1.4rem;
        padding-left: 3.4rem;
    `}
    &::placeholder {
        font-weight: 400;
        font-size: 1.6rem;
        color: ${secondTextColor};
        opacity: 0.5;
        ${media.mdMax`
            font-size: 1.4rem;
        `}
    }
    input::-ms-reveal,
    input::-ms-clear {
        display: none;
    }
    &:focus {
        border: none;
        height: calc(100% - 0.2rem);
        width: calc(100% - 0.2rem);
        top: 0.1rem;
        left: 0.1rem;
    }
    &:focus + ${Border} {
        display: block;
    }
`;

export const FormItemInputContent = styled.input<IProps>`
    position: relative;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 120%;
    color: #000000;
    outline: none;
    box-shadow: none;
    background: #ffffff;
    padding: 0 4rem;
    border-radius: 3rem;
    border: 1px solid #a7c3da;
    display: flex;
    align-items: center;
    border-color: ${({ criticalityLevel }) => {
        if (criticalityLevel === 1) {
            return "#e7a600";
        } else if (criticalityLevel === 2) {
            return errorColor;
        } else {
            return "#a7c3da";
        }
    }};
    ${media.mdMax`
        font-size: 1.4rem;
        padding-left: 3.4rem;
    `}
    &::placeholder {
        font-weight: 400;
        font-size: 1.6rem;
        color: ${secondTextColor};
        opacity: 0.5;
        ${media.mdMax`
            font-size: 1.4rem;
        `}
    }
    input::-ms-reveal,
    input::-ms-clear {
        display: none;
    }
    &:focus {
        border: none;
        height: calc(100% - 0.2rem);
        width: calc(100% - 0.2rem);
        top: 0.1rem;
        ${rtl`left: 0.1rem;`};
    }
    &:focus + ${Border} {
        display: block;
    }
`;

interface IFormError {
    isWarning?: boolean;
}

export const FormError = styled.div<IFormError>`
    margin: 0 2rem;
    font-size: 1rem;
    position: absolute;
    color: ${errorColor};
    color: ${({ isWarning }) => (isWarning ? "#e7a600" : errorColor)};
`;
