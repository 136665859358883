import { useTranslation } from "react-i18next";
import { Form, SendButton } from "../../styled/layouts";
import { CustomInput } from "../custom-input";
import { useFormikConsoleErrors } from "components/formik/use-formik-console-errors";
// import { CheckAgreements } from "../check-agreements";
import { useMemo, useRef } from "react";
import * as yup from "yup";
import { useTranslationList } from "hooks/use-translations-list";
import { errorHandler } from "services/error-handler";
import { useMutation } from "@apollo/client";
import {
    SendAdvice,
    SendAdviceVariables,
} from "gql/types/operation-result-types";
import RECEIVE_LETTER_MUTATION from "./receive-letter.gql";
import { successHandler, SuccessTypes } from "services/success-handler";
import { useLanguage } from "@webx-react/i18n";

interface IProps {
    buttonType: any;
    onSubmit?: () => void;
}

const ERRORS = {
    ONE_WORD: 6,
};

export const SendForm = React.memo(({ buttonType, onSubmit }: IProps) => {
    const field = useRef("Name");
    const [__] = useTranslation();
    const initialValues = {
        name: "",
        email: "",
        rulesConfirmed: true,
    };
    const { FIELD_SHOULD_BE_FILLED, INVALID_FORMAT } = useTranslationList();

    const [mutation] = useMutation<SendAdvice, SendAdviceVariables>(
        RECEIVE_LETTER_MUTATION,
    );

    const { language } = useLanguage();
    const instructionLangName = useMemo(() => {
        let langName = language.shortName.toLocaleUpperCase();
        if (!availableInstructionLangNames.includes(langName)) {
            langName = "EN";
        }
        return langName;
    }, [language.shortName]);

    const validationSchema = useMemo(
        () =>
            yup.object().shape({
                name: yup.string().required(FIELD_SHOULD_BE_FILLED),
                email: yup
                    .string()
                    .required(FIELD_SHOULD_BE_FILLED)
                    .email(INVALID_FORMAT),
                rulesConfirmed: yup.boolean().required(FIELD_SHOULD_BE_FILLED),
            }),
        [FIELD_SHOULD_BE_FILLED, INVALID_FORMAT],
    );

    const formik = useFormikConsoleErrors<typeof initialValues>({
        initialValues,
        onSubmit: async ({ name, email, rulesConfirmed }) => {
            try {
                await mutation({
                    variables: {
                        name,
                        email: email.toLowerCase(),
                        agreeUsePersonalData: rulesConfirmed,
                    },
                });

                if (onSubmit) {
                    onSubmit();
                }

                const downloadPDFLink = document.createElement("a");
                downloadPDFLink.setAttribute(
                    "href",
                    `/assets/pdf/bizbet/${instructionLangName.toLowerCase()}/advice.pdf`,
                );
                downloadPDFLink.setAttribute("download", "");
                downloadPDFLink?.click();
                downloadPDFLink.remove();
                successHandler(SuccessTypes.RECEIVE_LETTER);
            } catch (ex: any) {
                void errorHandler(ex, error => {
                    switch (error.data.code) {
                        case ERRORS.ONE_WORD:
                            return format(
                                __("Поле {field} необходимо указать в 1 слово"),
                                {
                                    field: field.current,
                                },
                            );
                        default:
                            return __(
                                "Что-то пошло не так, ошибка: FormikConsoleErrors",
                            );
                    }
                });
            }
        },
        validationSchema,
        enableReinitialize: true,
    });

    return (
        <Form onSubmit={formik.handleSubmit} id="sendForm">
            <CustomInput
                touched={formik.touched.name}
                errors={formik.errors.name}
                name="name"
                type="text"
                placeholder={`${__("Имя")}`}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.name}
                required
            />
            <CustomInput
                touched={formik.touched.email}
                errors={formik.errors.email}
                id="email"
                name="email"
                type="text"
                placeholder={__("Email")}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                required
            />
            {/* TODO раскоментить как только будет известнв логика реализации данного блока от заказчика */}
            {/* <CheckAgreements
                    formik={formik}
                    buttonText={__("Получить совет")}
                    colorLink="#4c4444"
                /> */}
            <SendButton type={buttonType}>
                {__("Получить совет")} &#128521;
            </SendButton>
        </Form>
    );
});

const availableInstructionLangNames = [
    "EN",
    "RU",
    "TR",
    "PT",
    "AE",
    "BD",
    "UZ",
];
