import styled from "styled-components";
import { media } from "services/styled-components/variables";
import { rtl } from "services/styled-components/rtl";

export const EyeWrapper = styled.div`
    position: absolute;
    height: 1.3rem;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    ${rtl`right: 2rem;`};
`;

export const CustomPasswordWrapper = styled.div`
    position: relative;
    width: 38.7rem;
    & #passwordTooltip {
        position: absolute !important;
        top: auto !important;
        left: 0 !important;
        box-sizing: border-box;
        width: 100%;
        bottom: 6rem;
    }
    ${media.mdMax`
        width: 28.8rem;
        & #passwordTooltip {
            bottom: 4.8rem;
        }
    `}
`;
