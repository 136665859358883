import styled from "styled-components";
import { media } from "services/styled-components/variables";
import { backgroundColor } from "../../../../services/styled-components/variables";
import { StateOfLink } from "../../../../styled/layouts";

interface IProps {
    isOpenedMobileMenu: boolean;
}

export const NavMobMenu = styled.div<IProps>`
    display: none;

    ${props => media.mdMax`
        position: fixed;
        display: block;
        height: 100vh;
        ${
            !props.isOpenedMobileMenu &&
            `
                display: none;
            `
        }
        z-index: 1;
        top: 0;
        right: 0;
        width: 100%;
        color: #ffffff;
        background: ${backgroundColor};
        transition: right 200ms ease 0ms;
    `}
`;

export const NavMobMenuWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    height: 100%;
    padding-top: 3.5em;

    & > a {
        display: inline-block;
        max-width: 13em;
        padding: 1em;
    }
`;

export const NavMobMenuCross = styled.span`
    ${media.mdMax`
        position: absolute;
        top: 1.7em;
        right:  1.7em;
        display: block;
        width: 1.5em;
        height: 1.5em;

        & i {
            position: absolute;
            top: 50%;
            left: 0;
            width: 100%;
            display: block;
            overflow: hidden;
            height: 1px;
            background: #ffffff;
            border-radius: 0.3em;
            transition: all 300ms ease 0ms;
            &:nth-child(1) {
                transform: rotate(-45deg);
            }
            &:nth-child(2) {
                transform: rotate(45deg);
            }
            &:nth-child(3) {
                display: none;
            }
        }
    `}
`;

export const NavMobList = styled.nav`
    display: flex;
    flex-direction: column;
    align-items: start;
    font-size: 1.7em;
    font-weight: 400;
    text-align: center;

    & a {
        display: block;
        position: relative;
        padding-bottom: 0.5em;
        margin-bottom: 0.5em;
        color: #ffffff;
        ${StateOfLink}
    }
`;

export const HorizontalLine = styled.div`
    height: 1px;
    width: 100%;
    background: rgba(255, 255, 255, 0.1);
    margin: 2em 0 3em;
`;
