import moment from "moment";
import { Variables } from "services/variables";
import {
    BlogBlock,
    Img,
    Description,
    GrayText,
    BlogTitle,
    BlogContent,
} from "../../styled";
import { LocalizedNavLink } from "components/language-provider";

interface IProps {
    newsLink: string;
    newsImgUrl?: string;
    newsDataTime: string;
    newsTitle: string;
    newsExcerpt: any;
    language: string;
}

export const PostCard = (props: IProps) => {
    return (
        <BlogBlock>
            <LocalizedNavLink to={`/blog/${props.newsLink}`}>
                {props.newsImgUrl && (
                    <Img
                        src={`${Variables.fileStoragePath}/${props.newsImgUrl}`}
                        alt="news_preview_image"
                        role="presentation"
                    />
                )}
            </LocalizedNavLink>
            <Description>
                <GrayText>
                    {moment(props.newsDataTime)
                        .locale(props.language)
                        .format("L")}
                </GrayText>
            </Description>
            <BlogTitle to={`/blog/${props.newsLink}`}>
                {props.newsTitle}
            </BlogTitle>
            <BlogContent>{props.newsExcerpt}</BlogContent>
        </BlogBlock>
    );
};
