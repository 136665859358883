import { HttpStatus } from "components/http-status";
import { useTranslation } from "react-i18next";
import {
    Title,
    LinkButton,
    ArticleTitle,
    WrapperPlainText,
} from "../../../styled/layouts";
import { CenterButtonContainer } from "./styled";

export const NotFound = () => {
    const [__] = useTranslation();

    return (
        <HttpStatus code={404}>
            <ArticleTitle>{__("Страница не найдена")}</ArticleTitle>
            <WrapperPlainText>
                <Title>404</Title>
                <CenterButtonContainer>
                    <LinkButton to="/">{__("Перейти на главную")}</LinkButton>
                </CenterButtonContainer>
            </WrapperPlainText>
        </HttpStatus>
    );
};
