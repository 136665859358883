import { GetMessengers_messengers } from "gql/types/operation-result-types";
import { ICustomInput } from "../../../../../../../components/custom-input";
import { Wrapper, Select, Selected, ItemsWrapper } from "./styled";
import facebookImg from "../../../../../../../assets/images/icons/facebook.svg";
import instagramImg from "../../../../../../../assets/images/icons/instagram.svg";
import skypeImg from "../../../../../../../assets/images/icons/skype.svg";
import telegramImg from "../../../../../../../assets/images/icons/telegram.svg";
import twitterImg from "../../../../../../../assets/images/icons/twitter.svg";
import whatsappImg from "../../../../../../../assets/images/icons/whatsapp.svg";
import { SelectItem } from "../../../../components/custom-select/styled";

interface IMessengerOption {
    value: GetMessengers_messengers;
    label: string;
}

interface IProps extends ICustomInput {
    items: IMessengerOption[];
    curItem?: IMessengerOption;
    active?: boolean;
    setActive: (active: boolean) => void;
    onSelectItem: (value: IMessengerOption) => void;
}

export const MessengerSelect = ({
    items,
    onSelectItem,
    curItem,
    active,
    setActive,
}: IProps) => {
    const images = new Map([
        [2, skypeImg],
        [3, whatsappImg],
        [4, telegramImg],
        [5, facebookImg],
        [6, twitterImg],
        [15, instagramImg],
    ]);
    const itemsWithImg = items.map(item => {
        return {
            ...item,
            img: images.get(item.value.id),
        };
    });
    const curItemWithImg = {
        ...curItem,
        img:
            curItem?.value?.id !== undefined
                ? images.get(curItem?.value?.id)
                : undefined,
    };

    return (
        <Wrapper onClick={() => setActive(!active)}>
            <Select>
                {itemsWithImg.map((item, i) => {
                    return (
                        <option value={item.value.id} key={i}>
                            {item.label}
                        </option>
                    );
                })}
            </Select>
            <Selected active={active}>
                <img src={curItemWithImg.img} />
            </Selected>
            <ItemsWrapper active={active}>
                {itemsWithImg.map((item, i) => {
                    return (
                        curItemWithImg.value?.id !== item.value.id && (
                            <SelectItem
                                onClick={() => {
                                    onSelectItem(item);
                                }}
                                key={i}
                            >
                                <img src={item.img} /> <span>{item.label}</span>
                            </SelectItem>
                        )
                    );
                })}
            </ItemsWrapper>
        </Wrapper>
    );
};
