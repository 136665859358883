import { Caption, FormWrapperForThirdStep } from "../styled";
import { CustomInput } from "../../../../../../components/custom-input";
import { IRegistrationStep } from "../hooks/use-step-data";
import { useTranslation } from "react-i18next";
import { CustomPassword } from "../../../components/custom-password";
import { CheckAgreements } from "../../../../../../components/check-agreements";
import { accentColor } from "../../../../../../services/styled-components/variables";

export const ThirdStep = ({ formik }: IRegistrationStep) => {
    const [__] = useTranslation();

    return (
        <>
            <Caption>
                {format(
                    __(
                        "{name}, осталось придумать данные для входа в ваш аккаунт",
                    ),
                    {
                        name: formik.values.firstname,
                    },
                )}
            </Caption>
            <FormWrapperForThirdStep>
                <CustomInput
                    touched={formik.touched.login}
                    errors={formik.errors.login}
                    name="login"
                    id="login"
                    type="text"
                    placeholder={`${__("Имя пользователя")}`}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.login}
                    required
                />
                <CustomPassword
                    touched={formik.touched.password}
                    errors={formik.errors.password}
                    autoComplete="new-password"
                    name={"password"}
                    id="password"
                    placeholder={`${__("Пароль")}`}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.password}
                    showPasswordTip
                    required
                />
                <CustomPassword
                    touched={formik.touched.confirmPassword}
                    errors={formik.errors.confirmPassword}
                    autoComplete="off"
                    name={"confirmPassword"}
                    id="confirmPassword"
                    placeholder={`${__("Подтвердите пароль")}`}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.confirmPassword}
                    required
                />
                <CheckAgreements
                    formik={formik}
                    buttonText={__("Завершить регистрацию")}
                    linkColor={accentColor}
                    showConfirmMailing
                />
            </FormWrapperForThirdStep>
        </>
    );
};
