import styled from "styled-components";
import { media } from "services/styled-components/variables";
import {
    accentColor,
    secondTextColor,
} from "../../../../../services/styled-components/variables";

export const QuestionBlock = styled.div`
    cursor: pointer;
    width: 79.4rem;
    max-width: 90%;
    max-height: 11.3rem;
    margin-bottom: 1rem;
    margin-left: auto;
    margin-right: auto;
    background: #ffffff;
    padding: 2.5rem 3rem 1.8rem;
    box-sizing: border-box;
    position: relative;
    ${media.smMax`
        border-radius: 2rem;
        padding: 1.7rem 2.5rem 0.8rem 2.5rem;
    `}
    &.answer-content-enter {
        max-height: 11.3rem;
    }
    &.answer-content-enter-active {
        max-height: 50rem;
        transition: 0.4s;
    }
    &.answer-content-enter-done {
        max-height: 50rem;
    }
    &.answer-content-exit {
        max-height: 50rem;
    }
    &.answer-content-exit-active {
        max-height: 11.3rem;
        transition: 0.4s;
    }
    &:before {
        content: "";
        display: block;
        width: 2.2rem;
        height: 0.3rem;
        position: absolute;
        top: 3.6rem;
        right: 4rem;
        border-radius: 0.2rem;
        background: ${secondTextColor};
        ${media.smMax`
            top:50%;
            margin-top: -0.15rem;
            right: 2.4em;
            width: 1.9rem;
        `}
    }
    &:after {
        content: "";
        display: block;
        width: 2.2rem;
        height: 0.3rem;
        position: absolute;
        top: 3.6rem;
        right: 4rem;
        border-radius: 0.2rem;
        background: ${secondTextColor};
        transform: rotate(90deg);
        ${media.smMax`
              top:50%;
            margin-top: -0.15rem;
             right: 2.4rem;
             width: 1.9rem;
        `}
    }
    &.answer-content-enter:after {
        transform: rotate(90deg);
    }
    &.answer-content-enter-active:after {
        transform: rotate(0deg);
        transition: 0.3s;
    }
    &.answer-content-enter-done:after {
        transform: rotate(0deg);
    }
    &.answer-content-exit:after {
        transform: rotate(0deg);
    }
    &.answer-content-exit-active:after {
        transform: rotate(90deg);
        transition: 0.3s;
    }
`;

export const Question = styled.p`
    text-decoration: none;
    font-size: 2rem;
    font-weight: 500;
    margin-bottom: 0.8rem;
    margin-top: 0;
    padding-right: 4rem;
    line-height: 120%;
    color: ${accentColor};
    ${media.smMax`
        font-size: 1.4rem;
    `}
`;

export const Answer = styled.div`
    max-height: 0;
    font-size: 1.6rem;
    margin: 0;
    margin-bottom: 0;
    opacity: 0;
    overflow: hidden;
    transition: opacity 200ms, transform 400ms, max-height 300ms;
    ${media.smMax`
        font-size: 1.2rem;
    `}
    .answer-content-enter & {
        opacity: 1;
        transform: translateY(-20%);
    }
    .answer-content-enter-active & {
        max-height: 40rem;
        opacity: 1;
        transform: translateY(0);
        transition: opacity 300ms, transform 400ms, max-height 1000ms;
    }
    .answer-content-enter-done & {
        max-height: 40rem;
        opacity: 1;
    }
    .answer-content-exit & {
        max-height: 0;
        opacity: 0;
    }
    .answer-content-exit-active & {
        opacity: 0;
        transform: translateY(-20%);
        transition: opacity 300ms, transform 400ms, max-height 1000ms;
    }
`;
