import { SearchWrapper, SearchInput, Img } from "./styled";
import { useTranslation } from "react-i18next";
import SearchImg from "../../../../../../assets/images/icons/search.svg";
import { IOption } from "..";

interface IProps {
    items: IOption[];
    setItems: (items: IOption[]) => void;
}

export const Search = ({ items, setItems }: IProps) => {
    const [__] = useTranslation();
    const handleInput = (e: any) => {
        const filtredItems = items.filter(item => {
            const value = e.target.value.toLowerCase().trim();
            return item.label.toLowerCase().includes(value);
        });
        setItems(filtredItems);
    };
    return (
        <SearchWrapper>
            <SearchInput
                onInput={handleInput}
                placeholder={__("Поиск")}
            ></SearchInput>
            <Img src={SearchImg} />
        </SearchWrapper>
    );
};
