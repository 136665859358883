import { SliderContainer, SliderBlock, ListForDesktop } from "./styled";
import { useTranslation } from "react-i18next";
import ReactSlick from "react-slick";

export const Slider = () => {
    const [__] = useTranslation();
    const settings = {
        dots: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        buttons: false,
    };
    const items = [
        {
            header: __(
                "Соберите как можно больше информации о своей аудитории.",
            ),
            text: __(
                "Если вы будете знать свою аудиторию, это поможет вам решить, какие размещать предложения и на сайты каких букмекеров перенаправлять пользователей.",
            ),
        },
        {
            header: __(
                "Позаботьтесь о достаточном количестве маркетинговых материалов.",
            ),
            text: __(
                "Использование баннеров, изображений и прочих маркетинговых материалов, предоставляемых букмекерами, позволит вам лучше нацеливать предложения на пользователей.",
            ),
        },
        {
            header: __("Следите за эволюцией рынка."),
            text: __(
                "Не важно, регулируемый перед вами рынок или нет, — эта индустрия отличается динамичностью, поэтому вам необходимо всегда следить за свежей информацией, чтобы ваши промоакции непременно были привлекательным и релевантными.",
            ),
        },
        {
            header: __("Сохраняйте гибкость."),
            text: __(
                "Хотя вы в первую очередь сосредоточены на спорте, всегда имейте на готове другие предложения. Киберспорт и виртуальный спорт — отличный выбор в те периоды, когда обычные турниры не проводятся.",
            ),
        },
    ];
    return (
        <>
            <ListForDesktop>
                {items.map((el, i) => (
                    <SliderBlock key={i}>
                        <h6>{el.header}</h6>
                        <span>{el.text}</span>
                    </SliderBlock>
                ))}
            </ListForDesktop>
            <SliderContainer>
                <ReactSlick {...settings}>
                    {items.map((el, i) => (
                        <SliderBlock key={i}>
                            <h6>{el.header}</h6>
                            <span>{el.text}</span>
                        </SliderBlock>
                    ))}
                </ReactSlick>
            </SliderContainer>
        </>
    );
};
