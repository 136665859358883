import { ReactNode } from "react";
import { Route } from "react-router-dom";

interface IInjectedProps {}

interface IProps extends IInjectedProps {
    children: ReactNode | null;
    code: number;
}

export const HttpStatus = React.memo((props: IProps) => {
    return (
        <Route
            render={({ staticContext }) => {
                if (staticContext) {
                    staticContext.statusCode = props.code;
                }
                return props.children;
            }}
        />
    );
}) as React.ComponentType<Subtract<IProps, IInjectedProps>>;
