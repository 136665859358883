import { ContentLoader } from "components/components-common/content-loader";
import { List } from "react-content-loader";
import { ArticleTitle, ArticleWrapper } from "../../../styled/layouts";
import { useCookiesPolicy } from "hooks/query/use-cookies-policy";

export const Cookies = () => {
    const { policy, loading } = useCookiesPolicy();

    return (
        <>
            <ArticleTitle>Cookies</ArticleTitle>
            <ContentLoader component={List} times={10} loading={loading}>
                <ArticleWrapper
                    dangerouslySetInnerHTML={{
                        // eslint-disable-next-line @typescript-eslint/naming-convention
                        __html: policy,
                    }}
                />
            </ContentLoader>
        </>
    );
};
