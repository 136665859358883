import styled from "styled-components";
import { media } from "services/styled-components/variables";
import { LocalizedNavLink } from "components/language-provider";
import {
    accentColor,
    backgroundColor,
    secondTextColor,
} from "../../../services/styled-components/variables";
import { Inboxes } from "@styled-icons/bootstrap/Inboxes/Inboxes";

export const Title = styled.h1`
    height: 15rem;
    background: ${backgroundColor};
    margin: 0;
    font-size: calc((100vw - 32rem) / (1920 - 320) * (70 - 30) + 3rem);
    font-weight: 700;
    letter-spacing: 0.03em;
    color: ${accentColor};
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    ${media.smMax`
        font-weight: 400;
        height: 10.8rem;
    `}
`;

export const Wrapper = styled.div`
    max-width: 130rem;
    padding: 10rem 3rem 0 3rem;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 2rem;
    ${media.smMax`
        padding-top: 3rem;
    `}
`;

export const BlogBlock = styled.div`
    width: 38.7rem;
    margin-bottom: 4rem;
    ${media.smMax`
        width: 28.8rem;
        margin-bottom: 0;
    `}
`;

export const Img = styled.img`
    border-radius: 3rem;
    width: 38.7rem;
    height: 21.7rem;
    cursor: pointer;
    ${media.smMax`
        border-radius: 2rem;
        width: 28.8rem;
        height: 16.1rem;
    `}
`;

export const Description = styled.div`
    display: flex;
    margin-top: 1.4rem;
    margin-bottom: 1.2rem;
    ${media.smMax`
        margin-top: 1.2rem;
        margin-bottom: 1rem;
    `}
`;

export const GrayText = styled.p`
    font-size: 1.4rem;
    font-weight: 400;
    color: ${secondTextColor};
    opacity: 0.5;
    margin: 0 2rem 0 0;
    ${media.smMax`
        font-size: 1.2rem;
        font-weight: 700;
        margin-right: 1.4rem;
    `}
`;

export const BlogTitle = styled(LocalizedNavLink)`
    font-weight: 700;
    font-size: 2rem;
    color: ${accentColor};
    margin-bottom: 1.2rem;
    margin-top: 1.6rem;
    ${media.smMax`
        font-weight: 400;
        font-size: 1.4rem;
        margin-bottom: 0.8rem;
    `}
`;

export const BlogContent = styled.p`
    font-size: 1.6rem;
    font-weight: 400;
    color: ${secondTextColor};
    ${media.smMax`
        font-size: 1.2rem;
        font-weight: 700;
        color: ${secondTextColor};
    `}
`;

export const NoDataContainer = styled.div`
    text-align: center;
`;
export const NoData = styled(Inboxes)`
    fill: ${accentColor};
`;
