import styled from "styled-components";
import { media } from "services/styled-components/variables";
import { secondTextColor } from "../../../../../../services/styled-components/variables";

export const SearchWrapper = styled.div`
    position: relative;
    margin: 1.5rem;
    box-sizing: border-box;
    width: 35.6rem;
    height: 3rem;
    margin-bottom: 1.5rem;
    margin-top: 4px;
    ${media.mdMax`
        width: 26rem;
    `}
`;

export const SearchInput = styled.input`
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding-left: 3.3rem;
    border-radius: 3rem;
    outline: none;
    box-shadow: none;
    border: none;
    background: #ffffff;
    font-size: 1.4rem;
    color: ${secondTextColor};
    &::placeholder {
        font-weight: 400;
        font-size: 1.4rem;
        color: ${secondTextColor};
        opacity: 0.5;
    }
    input::-ms-clear {
        display: none;
    }
`;

export const Img = styled.img`
    position: absolute;
    left: 1.5rem;
    top: 50%;
    transform: translateY(-50%);
`;
