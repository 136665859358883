import React, { useEffect } from "react";
import {
    NavMobMenu,
    NavMobMenuWrapper,
    NavMobMenuCross,
    NavMobList,
    HorizontalLine,
} from "./styled";
import urljoin from "url-join";
import { LocalizedNavLink, useLanguage } from "components/language-provider";
import { NavHashLink } from "react-router-hash-link";
import { ContactBlock } from "../../../../components/contacts";

interface IInjectedProps {}

interface IProps extends IInjectedProps {
    isOpenedMobileMenu: boolean;
    color: string;
    onClick?: () => void;
    menuItemsData: Array<{
        linkUrl: string;
        linkText: any;
    }>;
}

export const MenuMobile = React.memo(
    ({ isOpenedMobileMenu, onClick, menuItemsData }: IProps) => {
        const {
            language: { urlPrefix },
        } = useLanguage();

        useEffect(() => {
            document.body.style.overflow = isOpenedMobileMenu
                ? "hidden"
                : "visible";
        }, [isOpenedMobileMenu]);

        return (
            <NavMobMenu
                isOpenedMobileMenu={isOpenedMobileMenu}
                onClick={onClick}
            >
                <NavMobMenuWrapper>
                    <NavMobMenuCross>
                        <i></i>
                        <i></i>
                        <i></i>
                    </NavMobMenuCross>
                    <HorizontalLine />
                    <NavMobList>
                        {menuItemsData.map((el, i) => {
                            if (el.linkUrl[0] === "#") {
                                return (
                                    <div key={i}>
                                        {
                                            <NavHashLink
                                                smooth={true}
                                                to={urljoin(
                                                    "/",
                                                    urlPrefix,
                                                    "/",
                                                    el.linkUrl,
                                                )}
                                            >
                                                {el.linkText}
                                            </NavHashLink>
                                        }
                                    </div>
                                );
                            }
                            return (
                                <div key={i}>
                                    {
                                        <LocalizedNavLink to={el.linkUrl}>
                                            {el.linkText}
                                        </LocalizedNavLink>
                                    }
                                </div>
                            );
                        })}
                    </NavMobList>
                    <HorizontalLine />
                    <ContactBlock onlyInMobileMenu />
                </NavMobMenuWrapper>
            </NavMobMenu>
        );
    },
) as React.ComponentType<Subtract<IProps, IInjectedProps>>;
