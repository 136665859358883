/* eslint-disable no-nested-ternary */
import React, { useState } from "react";
import { Stage } from "hooks/sign-up/use-on-submit";
import { SignUpForm } from "./sign-up-form";
import { Utils } from "@webx/utils";
import { useLanguage } from "components/language-provider";

export const SignUp = React.memo(() => {
    const [stage, setStage] = useState(Stage.SIGN_UP);

    return (
        <>
            {stage === Stage.SUCCESS ? (
                <Redirect />
            ) : (
                <SignUpForm setStage={setStage} />
            )}
        </>
    );
});

const Redirect = () => {
    const {
        language: { urlPrefix },
    } = useLanguage();

    if (Utils.browser.hasWindow()) {
        const prefix = urlPrefix ? `/${urlPrefix}` : "";

        document.location.replace(
            `${window.location.origin}${prefix}/sign-up-complete`,
        );
    }
    return <></>;
};
