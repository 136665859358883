import { useState } from "react";
import { Eye } from "./eye";
import { CustomPasswordWrapper, EyeWrapper } from "./styled";
import { PasswordReactTooltip } from "components/components-common/react-tooltip";
import { CustomInput } from "../../../../../components/custom-input";

interface IProps extends React.InputHTMLAttributes<HTMLInputElement> {
    touched?: boolean | undefined;
    errors?: string | undefined;
    showPasswordTip?: boolean;
}

export const CustomPassword = ({ showPasswordTip, ...props }: IProps) => {
    const [EyeState, setEyeState] = useState(false);
    const password = props.value + "";
    const isStrongPassword =
        /(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z_#&/]{8,}/g.test(
            password,
        ) || !password;

    return (
        <CustomPasswordWrapper>
            <CustomInput
                {...props}
                warnings={!isStrongPassword}
                type={EyeState ? "text" : "password"}
                data-for={showPasswordTip ? "passwordTooltip" : undefined}
                data-tip={showPasswordTip ? true : undefined}
            />
            {showPasswordTip && <PasswordReactTooltip />}
            <EyeWrapper>
                <Eye active={EyeState} onClick={() => setEyeState(!EyeState)} />
            </EyeWrapper>
        </CustomPasswordWrapper>
    );
};
