import {
    gradientBackgraund,
    gradientColor,
    accentColor,
} from "./../../../services/styled-components/variables";
import styled, { css } from "styled-components";
import { LocalizedNavLink } from "components/language-provider";
import { media } from "services/styled-components/variables";
import { rtl } from "services/styled-components/rtl";

const LinkCSS = css`
    color: ${accentColor};
    font-size: 1.2rem;
    &:hover {
        text-decoration: underline;
    }
`;

export const GlobalLink = styled.a`
    ${LinkCSS}
`;

export const RouterLink = styled(LocalizedNavLink)`
    ${LinkCSS}
`;

export const FormCSS = css`
    margin-left: 18vw;
    padding-top: 12rem;
    display: flex;
    align-items: ${props =>
        props.theme.dir === "rtl" ? "flex-end" : "flex-start"};
    flex-direction: column;
    gap: 2rem;
    z-index: 0;
    ${media.lgMax`
        ${rtl`margin-left: 5vw;`};
    `};
    ${media.smMax`
        align-items: center;
        padding-top: 2rem;
        ${rtl`margin-left: 0;`};
    `}
    ${media.ltMax`
        gap: 1.6rem;
    `}
`;

export const AuthButton = styled.button`
    width: 38.6rem;
    height: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 1.6rem;
    color: #ffffff;
    line-height: 120%;
    text-transform: uppercase;
    background: ${accentColor};
    border-radius: 2.5rem;
    border: none;
    cursor: pointer;
    ${media.mdMax`
        width: 28.8rem;
        height: 4rem;
        font-size: 1.2rem;
    `}
    ${media.ltMax`
        margin: 0.8rem 0 2rem;
    `}
    &:hover {
        background: ${gradientBackgraund};
    }
    &:active {
        background: ${gradientColor};
    }
`;
