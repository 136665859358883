import styled from "styled-components";
import { media } from "services/styled-components/variables";
import { LocalizedNavLink } from "components/language-provider";
import {
    accentColor,
    secondTextColor,
} from "../../../../../services/styled-components/variables";
import { FormCSS } from "../../styled";

export const AuthWrapper = styled.div`
    min-height: 54rem;
    position: relative;
    ${media.smMax`
        min-height: calc(80vw + 35rem);
    `}
    & form {
        ${FormCSS};
    }
`;

export const Img = styled.img`
    position: absolute;
    right: 18vw;
    top: -5rem;
    z-index: -1;
    width: 55rem;
    ${media.lgMax`
        right: 5vw;
    `}
    ${media.smMax`
        top: auto;
        bottom: 5rem;
        right: 50%;
        width: 80vw;
        transform: translateX(50%);
    `}
    ${media.ltMax`
        bottom: 2rem;
    `}
`;

export const H1 = styled.h1`
    font-weight: 700;
    font-size: calc((100vw - 32rem) / (1920 - 320) * (50 - 20) + 2rem);
    line-height: 120%;
    color: ${secondTextColor};
    margin-top: 0;
    margin-bottom: 1rem;
    text-transform: uppercase;
    text-align: left;
    ${media.mdMax`
        margin-bottom: 0;
    `}
    ${media.ltMax`
        margin-bottom: 0.8rem;
    `}
`;

export const Caption = styled.p`
    margin: 0;
    font-weight: 500;
    font-size: calc((100vw - 32rem) / (1920 - 320) * (16 - 12) + 1.2rem);
    color: ${secondTextColor};
    ${media.ltMax`
        margin-bottom: 0.5rem;
    `}
`;

export const LinksBlock = styled.div`
    display: flex;
    justify-content: space-between;
    width: 38.7rem;
    margin-top: -0.7rem;
    ${media.mdMax`
        width: 28.8rem;
    `}
`;

export const Link = styled(LocalizedNavLink)`
    color: ${accentColor};
    font-size: 1.2rem;
    &:hover {
        text-decoration: underline;
    }
`;
