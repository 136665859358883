import React from "react";
import ReCAPTCHAElem, {
    ReCAPTCHAProps,
    ReCAPTCHA as GoogleReCAPTCHA,
} from "react-google-recaptcha";
import { Variables } from "services/variables";
import { useLanguage } from "components/language-provider";

type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

export type TReCAPTCHA = GoogleReCAPTCHA;

export const ReCAPTCHA = React.forwardRef<
    ReCAPTCHAElem,
    PartialBy<ReCAPTCHAProps, "sitekey">
>((props, ref) => {
    const { language } = useLanguage();
    return (
        <ReCAPTCHAElem
            ref={ref}
            hl={language.code}
            theme={"light"}
            sitekey={Variables.reCaptcha.siteKey}
            {...props}
        />
    );
});
