import styled from "styled-components";
import { media } from "services/styled-components/variables";
import { secondTextColor } from "../../../../services/styled-components/variables";

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: ${secondTextColor};
    padding-bottom: 12rem;
    position: relative;
    ${media.smMax`
        padding-bottom: 5rem;
    `}
`;

export const FaqTitle = styled.h1`
    font-weight: 700;
    font-size: 5rem;
    color: #ffffff;
    margin-top: 8rem;
    margin-bottom: 5.5rem;
    ${media.smMax`
        font-size: 2.6rem;
        margin-top: 4.4rem;
        margin-bottom: 2rem;
    `}
`;

export const Blob1 = styled.img`
    position: absolute;
    left: 0;
    top: 18.4rem;
    ${media.smMax`
        display: none;
    `}
`;

export const Blob2 = styled(Blob1)`
    left: auto;
    right: 0;
    top: 17.7rem;
`;
