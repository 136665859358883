import styled from "styled-components";
import {
    accentColor,
    secondTextColor,
} from "../../../../services/styled-components/variables";
import Bg from "../../../../assets/images/bgPartner.png";
import { media } from "services/styled-components/variables";
import { LocalizedLink } from "components/language-provider";
import { StateOfBtn } from "../../../../styled/layouts";

export const Wrapper = styled.div`
    background: url(${Bg}) center / cover no-repeat;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const Title = styled.h3`
    font-weight: 700;
    font-size: 7em;
    line-height: 120%;
    text-align: center;
    color: ${secondTextColor};
    padding: 1.7em 0 0.43em;
    margin: 0;

    ${media.lgMax`
        font-size: 3em;
        padding: 1.7em 0.5em 0.7em;
    `}
`;

export const Text = styled.p`
    font-weight: 400;
    font-size: 2em;
    color: #000;
    line-height: 150%;
    margin: 0 0 1.5em;
    max-width: 35.75em;
    text-align: center;
    ${media.lgMax`
        font-size: 1.2em;
        margin-bottom: 2.5em;
        padding: 0 1.3em;
    `}
`;

export const Button = styled(LocalizedLink)`
    font-size: 1.8em;
    font-weight: 700;
    border-radius: 3.3em;
    color: #fff;
    background: ${accentColor};
    padding: 1em 1.7em 1.1em;
    margin-bottom: 6.6em;
    min-width: 15rem;
    text-align: center;
    ${StateOfBtn};

    ${media.lgMax`
        font-size: 1.4em;
        padding: 0.7em 1em 0.9em;
        margin-bottom: 3.6em;
    `}
`;
