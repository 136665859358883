import { Answer, Question, QuestionBlock } from "./styled";

export const QuestionContent = (props: {
    question: string;
    answer: string | null;
    onClick: () => void;
}) => {
    return (
        <QuestionBlock onClick={() => props.onClick()}>
            <Question>{props.question}</Question>
            {props.answer && (
                <Answer
                    dangerouslySetInnerHTML={{
                        // eslint-disable-next-line @typescript-eslint/naming-convention
                        __html: props.answer,
                    }}
                />
            )}
        </QuestionBlock>
    );
};
