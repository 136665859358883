import { hintColor } from "./../../../../../services/styled-components/variables";
import styled from "styled-components";
import { media } from "services/styled-components/variables";
import Close from "../../../../../assets/images/icons/close.svg";
import { rtl } from "services/styled-components/rtl";

interface WrapperInfoProps {
    hintForSelect?: boolean;
}

export const WrapperInfo = styled.div<WrapperInfoProps>`
    position: absolute;
    width: 2rem;
    height: 2rem;
    z-index: 1;
    top: 50%;
    transform: translateY(-50%);
    ${rtl`right: ${(props: WrapperInfoProps) =>
        props.hintForSelect ? "4rem" : "1.5rem"};`};
    cursor: pointer;
`;

interface IHint {
    isSmall?: boolean;
    isOpen?: boolean;
}

export const Hint = styled.div<IHint>`
    display: none;
    ${media.mdMax`
        display: ${({ isOpen }: IHint) => (isOpen ? "block" : "none")};
    `}
    position: absolute;
    left: calc(100% - 4.5rem);
    bottom: 4rem;
    box-sizing: border-box;
    padding: 0.9rem 1.6rem;
    font-size: 1.2rem;
    background: ${hintColor};
    border-radius: 1.6rem;
    width: ${({ isSmall }) => (isSmall ? "21.8rem" : "36.9rem")};
    ${media.mdMax`
        width: 23.9rem;
        right: -1.5rem;
        left: auto;
        bottom: 3.3rem;
        line-height: 1.4;
    `}
    &::before {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        border: 11px solid transparent;
        border-top: 21px solid ${hintColor};
        top: calc(100% - 0.6rem);
        left: 2.4rem;
        ${media.mdMax`
            display: none;
        `}
    }
`;

export const CloseBtn = styled.div`
    display: none;
    position: absolute;
    width: 1.2rem;
    height: 1.2rem;
    background-image: url(${Close});
    right: 1rem;
    top: 1rem;
    cursor: pointer;
    ${media.mdMax`
        display: block;
    `}
`;

export const WrapperInfoIcon = styled.div`
    position: absolute;
    height: 100%;
    @media (min-width: 1024px) {
        &:hover + ${Hint} {
            display: block;
        }
    }
`;
