import styled from "styled-components";
import { accentColor } from "../../services/styled-components/variables";

export const IconLink = styled.a`
    display: flex;
    align-items: center;
    gap: 15px;
    line-height: 22px;
    color: rgb(255, 255, 255);
    cursor: pointer;
    text-decoration: underline;
    transition: all 0.2s;
    &:hover {
        color: ${accentColor};
        svg {
            fill: ${accentColor};
        }
    }
`;

export const IconsContainer = styled.div`
    display: flex;
    gap: 1.4rem;
    margin-top: 1rem;
`;
