import { useTranslation } from "react-i18next";
import { Wrapper, Title, Text, Button } from "./styled";

export const BecomePartner = () => {
    const [__] = useTranslation();
    return (
        <Wrapper>
            <Title>
                {format(__("Становитесь партнером {CompanyName}!"), {
                    CompanyName: "BizBet",
                })}
            </Title>
            <Text>
                {__(
                    "Ваш источник трафика - это ваши знания. Если вы любите спорт, слоты, кибер, крипту и можете рассказать об этом другим, значит вам будет легко зарабатывать. А если нужна помощь, мы всегда будем рядом!",
                )}
            </Text>
            <Button to="/sign-up">{__("Присоединиться")}</Button>
        </Wrapper>
    );
};
