import { ContentLoader } from "components/components-common/content-loader";
import { List } from "react-content-loader";
import { usePrivacyPolicy } from "hooks/query/use-privacy-policy";
import { ArticleTitle, ArticleWrapper } from "../../../styled/layouts";
import { useTranslation } from "react-i18next";

export const PrivacyPolicy = () => {
    const { text, loading } = usePrivacyPolicy();
    const [__] = useTranslation();
    return (
        <>
            <ArticleTitle>{__("Политика конфиденциальности")}</ArticleTitle>
            <ContentLoader component={List} times={10} loading={loading}>
                <ArticleWrapper
                    dangerouslySetInnerHTML={{
                        // eslint-disable-next-line @typescript-eslint/naming-convention
                        __html: text,
                    }}
                />
            </ContentLoader>
        </>
    );
};
