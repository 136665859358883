import { FirstScreen } from "./first-screen";
import { Faq } from "./faq";
import { WhyItWorks } from "./why-it-works";
import { People } from "./people";
import { Feedback } from "./feedback";
import { CanEarn } from "./everyone-can-earn";
import { BecomePartner } from "./become-partners";

export const Home = () => {
    return (
        <>
            <FirstScreen />
            <WhyItWorks />
            <CanEarn />
            <People />
            <Feedback />
            <BecomePartner />
            <Faq />
        </>
    );
};
