import styled from "styled-components";
import earnBg from "../../../../assets/images/earn/earn-bg.png";
import { media } from "services/styled-components/variables";
import { Title } from "../../../../styled/layouts";

export const Wrapper = styled.div`
    /* background: url(${earnBg}) center / cover no-repeat; */
    color: #fff;
    position: relative;
    background: linear-gradient(90deg, #db5318 0%, #fca259 100%);
`;

export const RightImage = styled.img`
    position: absolute;
    right: 8%;
    top: 16%;
    z-index: 0;

    ${media.mdMax`
        right: 0;
        top: 0;
        transform: scale(.8);
    `}

    ${media.smMax`
        display: none;
    `}
`;

export const Container = styled.div`
    position: relative;
    padding: 12em 16em 8em 36em;
    display: flex;
    flex-direction: column;
    align-items: ${props =>
        props.theme.dir === "rtl" ? "flex-end" : "flex-start"};

    @media screen and (max-width: 1800px) {
        padding-left: 15em;
    }

    ${media.mdMax`
        padding: 5em 1.6em;

    `}

    ${Title} {
        max-width: 19em;
        margin-bottom: 3.6rem;
        text-align: left;
        text-transform: none;

        ${media.ltMax`
            margin: 0 auto;
        `}

        ${media.lgMax`
            max-width: none;
            margin-bottom: 1.4rem;
            font-size: 2rem;
        `}
    }
`;
export const SubTitle = styled.h3`
    font-weight: 400;
    font-size: 3em;
    line-height: 120%;
    text-align: center;
    margin: 0 0 2rem 0;

    ${media.ltMax`
        display: block;
     `}
`;

export const Description = styled.p`
    font-weight: 400;
    font-size: 1.4em;
    line-height: 160%;
    max-width: 32em;
    margin: 0 0 3rem 0;
    ${media.lgMax`
        font-size: 1.4em;
        margin: 0 0 2rem 0;
    `}
`;

export const SocialWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: ${props =>
        props.theme.dir === "rtl" ? "flex-start" : "flex-end"};
    width: 100%;
    gap: 9em;

    ${media.lgMax`
        gap: 1em;
        margin-top: 2em;
     `}
`;

export const Item = styled.div`
    display: flex;
    gap: 2em;
    height: 6em;

    ${media.lgMax`
        gap: 0.6em;
        flex: 1;
     `}
`;
export const IconWrapper = styled.div`
    width: 6em;
    height: 6em;
    background: #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    ${media.lgMax`
        max-width: 4em;
        width: 100%;
        height: 4em;
    `}
`;

export const Icon = styled.img`
    ${media.lgMax`
        max-width: 2.5em;
    `}
`;

export const Info = styled.div`
    display: flex;
    flex-direction: column;
    align-items: space-between;
    font-size: 3em;

    ${media.lgMax`
        font-size: 2em;
    `}
`;

export const InfoTitle = styled.h5`
    margin: 0;
    line-height: 120%;

    ${media.lgMax`
        font-size: 2rem;
    `}
`;

export const InfoDescription = styled.p`
    font-size: 0.6em;
    line-height: 160%;
    margin: 0;
`;
