import { useTranslation } from "react-i18next";
import HumanRegFin from "../../../../assets/images/registration/humanRegFin.svg";
import { Title, Paragraph, Banner, Wrapper, Link, Content } from "./styled";
import { useDefinedContext } from "hooks/use-context-exist";
import { PartnersProgramGeneralInformationContext } from "views";
import { useLanguage } from "@webx-react/i18n";

export const RegistrationComplete = () => {
    const [__] = useTranslation();
    const { language } = useLanguage();
    const langCode = language.shortName.toLowerCase();
    let { telegramm } = useDefinedContext(
        PartnersProgramGeneralInformationContext,
    );
    if (langCode !== "tr") {
        telegramm = "https://t.me/bizbet_partners";
    }
    const [meet, part1, telegram, part2] = __(
        "Рады знакомству!🤝{separator}Пускай наше сотрудничество будет плодотворным. Наш менеджер скоро с вами свяжется. Прием заявки может занять 48 часов. Обязательно подпишитесь на наш {separator}Телеграм{separator}, где мы всегда на связи 😊",
    ).split("{separator}");
    return (
        <Wrapper>
            <Content>
                <Title>{__("Спасибо за регистрацию!")}</Title>
                <Paragraph>{meet}</Paragraph>
                <Paragraph>
                    <span>{part1}</span>
                    {telegramm && (
                        <Link
                            target="_blank"
                            rel="noopener noreferrer"
                            href={telegramm}
                        >
                            {telegram}
                        </Link>
                    )}
                    <span>{part2}</span>
                </Paragraph>
            </Content>
            <Banner src={HumanRegFin} />
        </Wrapper>
    );
};
