import { useLanguage } from "components/language-provider";
// import { Pagination } from "../pagination";
import { NoDataContainer, NoData, Title, Wrapper } from "../styled";
import { ConditionalList } from "components/conditional-list";
import { useNews } from "views/landing/news/hooks/use-news";
import { NoData as NoDataText } from "components/no-data";
import { useTranslation } from "react-i18next";
import { GetNewsList_partnersProgram_news_getNewsList_news } from "gql/types/operation-result-types";
import { PostCard } from "./post-card";
import { RootHelmetAndScriptsBodyInjector } from "components/root-helmet";
import { metaData } from "../../index";

export const AllPosts = () => {
    const { language } = useLanguage();
    const { loading, error, newsList } = useNews(language.code, "blog");
    const [__] = useTranslation();

    return (
        <>
            <RootHelmetAndScriptsBodyInjector
                titleText={format(
                    __(
                        "Блог партнерской программы {CompanyName}: новости, советы, обновления",
                    ),
                    metaData,
                )}
                metaText={format(
                    __(
                        "На блоге партнерской программы {CompanyName} вы найдете свежие новости, полезные советы и интересные обновления. Будьте в курсе всех событий в мире партнерства с нами.",
                    ),
                    metaData,
                )}
            />
            <Title>{__("Блог")}</Title>
            <Wrapper>
                <ConditionalList
                    error={error}
                    loading={loading}
                    data={newsList}
                    renderEmpty={() => (
                        <NoDataContainer>
                            <NoData size={50} />
                            <NoDataText />
                        </NoDataContainer>
                    )}
                    renderExist={(
                        data: GetNewsList_partnersProgram_news_getNewsList_news[],
                    ) => (
                        <>
                            {data.map(item => (
                                <PostCard
                                    key={item.id}
                                    newsLink={item.url}
                                    newsDataTime={item.datePublick}
                                    newsTitle={item.title}
                                    newsExcerpt={item.shortText}
                                    language={language.code}
                                    newsImgUrl={item.PImage}
                                />
                            ))}
                        </>
                    )}
                />
                {/* <Pagination countPages={101} /> */}
            </Wrapper>
        </>
    );
};
