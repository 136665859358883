import { useTranslation } from "react-i18next";
import OrangeBlob from "../../../../assets/images/orangeBlob.svg";
import WhiteBlob from "../../../../assets/images/whiteBlob.svg";
import { Wrapper, FaqTitle, Blob1, Blob2 } from "./styled";
import { QuestionContent } from "./question-content";
import { ContentLoader } from "components/components-common/content-loader";
import { List } from "react-content-loader";
import { useFaq } from "hooks/query/use-faq";
import { useState } from "react";
import { CSSTransition } from "react-transition-group";

export const Faq = React.memo(() => {
    const [__] = useTranslation();
    const { faqContent, loading } = useFaq();
    const questions = faqContent && faqContent[0]?.questions;
    const [openedQuestion, setOpenedQuestion] = useState<string | undefined>();

    return (
        <Wrapper id="faq">
            <FaqTitle>{__("Часто задаваемые вопросы")}</FaqTitle>
            <Blob1 src={WhiteBlob} />
            <Blob2 src={OrangeBlob} />
            <ContentLoader
                component={List}
                times={10}
                loading={loading}
                text={faqContent && faqContent.length > 0 ? " " : ""}
            >
                {questions && (
                    <>
                        {questions?.map((item, index) => (
                            <CSSTransition
                                key={index}
                                in={openedQuestion === `question_${index}`}
                                timeout={300}
                                classNames="answer-content"
                            >
                                <QuestionContent
                                    question={item.question}
                                    answer={item.answer}
                                    onClick={() =>
                                        setOpenedQuestion(
                                            `question_${index}` ===
                                                openedQuestion
                                                ? undefined
                                                : `question_${index}`,
                                        )
                                    }
                                />
                            </CSSTransition>
                        ))}
                    </>
                )}
            </ContentLoader>
        </Wrapper>
    );
});
