import { useLanguage } from "components/language-provider";
import { withRouter } from "react-router-dom";
import { Variables } from "services/variables";
import { useCurrentNew } from "views/landing/news/hooks/use-current-new";
import { WrapperPlainText as BlogWrapper } from "../../../../styled/layouts";
import { useTranslation } from "react-i18next";
import { RootHelmetAndScriptsBodyInjector } from "components/root-helmet";
import { ErrorText } from "components/conditional-list";
import { LoadingText } from "components/no-data";

interface IInjectedProps {
    match: {
        params: {
            name: string;
        };
    };
}

interface IProps extends IInjectedProps {}

export const CurrentPost = withRouter(
    React.memo((props: IProps) => {
        const { language } = useLanguage();
        const { match } = props;
        const { loading, error, currentNew } = useCurrentNew(
            language.code,
            match.params.name,
        );
        const [__] = useTranslation();

        if (error) {
            return <ErrorText>{__("Ошибка")}</ErrorText>;
        }

        return loading || !currentNew ? (
            <LoadingText>{__("Загрузка")}</LoadingText>
        ) : (
            <>
                <RootHelmetAndScriptsBodyInjector
                    titleText={currentNew.metaTitle || undefined}
                    metaText={currentNew.metaDescription || undefined}
                />
                <BlogWrapper>
                    <h1>{currentNew.title}</h1>

                    {currentNew.image && (
                        <img
                            src={`${Variables.fileStoragePath}/${currentNew.image}`}
                            alt="news_preview_image"
                            role="presentation"
                        />
                    )}

                    <p>
                        {currentNew.mainText && (
                            <div
                                dangerouslySetInnerHTML={{
                                    // eslint-disable-next-line @typescript-eslint/naming-convention
                                    __html: currentNew.mainText,
                                }}
                            />
                        )}
                    </p>
                </BlogWrapper>
            </>
        );
    }),
) as React.ComponentType<Subtract<IProps, IInjectedProps>>;
