import styled from "styled-components";
import { media } from "services/styled-components/variables";
import {
    blockBgColor,
    gradientBackgraund,
} from "../../../../services/styled-components/variables";
export const ListForDesktop = styled.div`
    ${media.ltMax`
        display: none;
    `};
`;

export const SliderContainer = styled.div`
    width: 100%;
    overflow: hidden;
    display: none;
    & .slick-dots li {
        padding: 1.2rem 0.7rem 0;
    }
    & .slick-dots button {
        background: ${blockBgColor};
    }
    ${media.ltMax`
        display: block;
    `};
`;

export const SliderBlock = styled.div`
    height: 15.6rem;
    margin: 2rem 0px 3em;
    padding: 3rem 2.6rem 2.6rem 4rem;
    box-sizing: border-box;
    background: ${gradientBackgraund};
    border-radius: 3rem;
    max-width: 79.4rem;
    ${media.ltMax`
        width: calc(100vw - 32px);
        height: 18rem;
        border-radius: 2rem;
        padding: 2.3rem 2.1rem 2.1rem 2.1rem;
    `};
`;
