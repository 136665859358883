import styled from "styled-components";
import { backgroundColor } from "../../../../services/styled-components/variables";
import { media } from "services/styled-components/variables";
import { Title } from "../../../../styled/layouts";
import { rtl } from "services/styled-components/rtl";

export const ItemWrapper = styled.div`
    display: flex;
    gap: 1.6rem;
    flex-direction: ${props =>
        props.theme.dir === "rtl" ? "row-reverse" : "row"};

    ${media.mdMax`
        display: none;
    `}
`;

export const BlockWrapper = styled.div`
    background: ${backgroundColor};
    padding: 7em 0;

    ${Title} {
        margin-bottom: 7rem;

        ${media.lgMax`
            margin-bottom: 3rem;
        `}
    }

    ${media.lgMax`
        padding: 5em 0 5.5em;
    `}

    .slick-slide:not(.slick-active) {
        opacity: 0.2;

        ${ItemWrapper} {
            margin-right: 17em;
        }
    }

    .slick-slide.slick-active + .slick-slide {
        ${ItemWrapper} {
            margin: 0 0 0 17em;
        }
    }

    .slick-list {
        padding-bottom: 3em;
    }

    .slick-dots li button {
        background: #29292d;
    }

    @media screen and (min-width: 1700px) {
        .slick-arrow {
            bottom: -4.7rem;
        }

        .slick-prev {
            left: 56rem;
        }

        .slick-next {
            right: 56rem;
        }
    }
`;

export const ItemBlock = styled.div`
    margin: 0 auto;
    display: block;
    max-width: 190em;
    color: #fff;

    @media screen and (max-width: 1699px) {
        max-width: 90em;
    }

    ${media.mdMax`
        margin: 3em auto 0;
        padding: 0;
        max-width: 45em;
    `}
`;

export const ItemImg = styled.img`
    min-width: 12em;
    min-height: 12em;
    max-width: 12em;
    max-height: 12em;
    border-radius: 50%;

    ${media.mdMax`
        min-width: 10rem;
        min-height: 10rem;
        max-width: 10rem;
        max-height: 10rem;
    `}
`;

export const InfoItem = styled.div`
    flex-direction: column;
`;

export const ItemTitle = styled.h5`
    font-size: 2rem;
    line-height: 120%;
    margin: 0 0 2rem;
    ${rtl`text-align: left;`};

    ${media.mdMax`
        margin: 0 0 1rem;
        font-size: 1.6rem;
    `}
`;
export const ItemText = styled.p`
    font-size: 1.6rem;
    line-height: 150%;
    margin: 0 0 1.2rem;
    white-space: pre-wrap;
    ${rtl`text-align: left;`};

    ${media.mdMax`
        font-size: 1.2rem;
        margin-bottom: 2rem;
    `};
`;

export const ItemWrapperMob = styled.div`
    display: none;

    ${media.mdMax`
        max-width: 38.7em;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content:flex-start;
        align-items: flex-start;
    `}
`;

export const FirstInfo = styled.div`
    display: flex;
    gap: 1.6rem;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 1.6rem;
`;

export const LinkFeedback = styled.a`
    display: contents;
    color: inherit;
`;
