import { useDefinedContext } from "hooks/use-context-exist";
import { useTranslation } from "react-i18next";
import { PartnersProgramGeneralInformationContext } from "views";
import { Block, ColumnHeader, FooterElement } from "../../styled/layouts";
import { IconLink, IconsContainer } from "./styled";
import { EmailImg } from "../icons/email";
import { useLanguage } from "@webx-react/i18n";
import { InstagramWithCircle } from "@styled-icons/entypo-social/InstagramWithCircle";
import { TwitterWithCircle } from "@styled-icons/entypo-social/TwitterWithCircle";
import { Telegram as TelegramIcon } from "@styled-icons/bootstrap/Telegram";
import { FacebookCircle } from "@styled-icons/boxicons-logos/FacebookCircle";

interface IProps {
    align?: string;
    onlyInMobileMenu?: boolean;
}

export const ContactBlock = ({ align, onlyInMobileMenu }: IProps) => {
    const [__] = useTranslation();
    const { email } = useDefinedContext(
        PartnersProgramGeneralInformationContext,
    );
    const { language } = useLanguage();
    const langCode = language.shortName.toLowerCase();
    let { telegramm } = useDefinedContext(
        PartnersProgramGeneralInformationContext,
    );
    let instagram = "";
    let facebook = "";
    let twitter = "";

    if (langCode !== "tr") {
        telegramm = "https://t.me/bizbet_partners";
    }

    if (["ae", "uz", "bd"].includes(langCode)) {
        telegramm = null;
    }

    if (langCode === "tr") {
        instagram = "https://www.instagram.com/bizbet_turkey";
        twitter = "https://twitter.com/bizbet_turkey";
    } else if (langCode === "pt") {
        instagram = "https://www.instagram.com/bizbet_portugal";
        twitter = "https://twitter.com/bizbet_portugal";
        facebook = "https://www.facebook.com/portugalbizbet";
    }

    return (
        <Block allowInMobile>
            <ColumnHeader align={align}>{__("Контакты")}</ColumnHeader>
            <FooterElement
                as="a"
                href={`mailto:${email}`}
                target="_blank"
                rel="noopener noreferrer"
            >
                <EmailImg />
                {email}
            </FooterElement>
            <IconsContainer>
                {telegramm && (
                    <IconLink
                        target="_blank"
                        rel="noopener noreferrer"
                        href={telegramm}
                    >
                        <TelegramIcon size={20} />
                        {langCode !== "tr" &&
                            langCode !== "pt" &&
                            __("Мы в телеграмме")}
                    </IconLink>
                )}
                {instagram && (
                    <IconLink
                        target="_blank"
                        rel="noopener noreferrer"
                        href={instagram}
                    >
                        <InstagramWithCircle size={20} />
                    </IconLink>
                )}
                {facebook && (
                    <IconLink
                        target="_blank"
                        rel="noopener noreferrer"
                        href={facebook}
                    >
                        <FacebookCircle size={20} />
                    </IconLink>
                )}
                {twitter && (
                    <IconLink
                        target="_blank"
                        rel="noopener noreferrer"
                        href={twitter}
                    >
                        <TwitterWithCircle size={20} />
                    </IconLink>
                )}
            </IconsContainer>
            {onlyInMobileMenu && (
                <>
                    <FooterElement to="privacy">
                        {__("Политика конфиденциальности")}
                    </FooterElement>
                    <FooterElement to="cookies">Cookies</FooterElement>
                </>
            )}
        </Block>
    );
};
