import styled from "styled-components";
import { WrapperPlainText } from "../../../styled/layouts";
import { media } from "services/styled-components/variables";
import {
    accentColor,
    backgroundColor,
} from "../../../services/styled-components/variables";

export const AboutWrapper = styled(WrapperPlainText)`
    max-width: 79.4rem;
    margin: 0 auto;
    ${media.mdMax`
        padding: 0 16px 5rem 16px;
    `}

    b {
        font-weight: 500;
        font-size: 20px;
        line-height: 160%;
        display: block;
        ${media.mdMax`
            font-size: 14px;
        `}
    }
    h6 {
        margin: 0;
        color: #ffffff;
    }
    span {
        color: #ffffff;
        opacity: 1;
        margin-top: 16px;
        display: inline-block;
    }
    li {
        padding-left: 12px;
    }
`;

export const TitleBlock = styled.div`
    background: ${backgroundColor};
    color: ${accentColor};
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    padding: 4.5rem 49.3rem;
    font-weight: 700;

    ${media.mdMax`
        padding:3rem 1.5rem 3rem;
    `}
`;

export const Title = styled.h1`
    margin: 0;
    font-size: calc((100vw - 32rem) / (1920 - 320) * (70 - 30) + 3rem);
    letter-spacing: 0.03rem;
    line-height: 120%;
    white-space: nowrap;
    ${media.mdMax`
      font-size: 2rem;
      white-space: normal;
    `}
`;

export const TitleImg = styled.img`
    max-height: 9.4rem;

    ${media.mdMax`
            max-width: 12.3rem;
    `}
`;
