import styled, { css } from "styled-components";
import checkboxImg from "../../assets/images/icons/checkbox.svg";
import checkboxCheckedImg from "../../assets/images/icons/checkbox-checked.svg";
import checkboxErrorImg from "../../assets/images/icons/checkbox-error.svg";
import { media } from "services/styled-components/variables";
import {
    secondTextColor,
    errorColor,
} from "../../services/styled-components/variables";

/* stylelint-disable */

interface IFieldCheckRules {
    isValid?: boolean;
}

interface ICaption {
    linkColor?: string;
}

export const Input = styled.input`
    display: none;
`;

export const CheckboxWrapper = styled.div`
    display: flex;
    box-sizing: border-box;
    gap: 1rem;

    ${media.ltMax`
        padding: 0 1.9rem;
    `}

    ${media.mdMax`
        width: 100%;
    `}
`;

export const FieldCheckRules = styled.label<IFieldCheckRules>`
    width: 1.6rem;
    height: 1.6rem;
    display: inline-block;
    margin-top: 0.4rem;
    background-size: cover;
    flex-shrink: 0;
    cursor: pointer;
    input[type="checkbox"]:checked + & {
        background: url("${checkboxCheckedImg}");
    }
    ${({ isValid }) => {
        return isValid
            ? css`
                  background: url("${checkboxImg}");
              `
            : css`
                  background: url("${checkboxErrorImg}");
              `;
    }};
`;

export const Caption = styled.span<ICaption>`
    position: relative;
    font-size: 1.6rem;
    color: ${secondTextColor};
    a {
        color: ${props => props.linkColor};
    }
`;

export const FormError = styled.div`
    position: absolute;
    top: 100%;
    font-size: 1.2rem;
    color: ${errorColor};
`;
