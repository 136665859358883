import styled from "styled-components";
import Arrow from "../../../../../../../assets/images/icons/arrow.svg";
import { media } from "services/styled-components/variables";
import { blockBgColor } from "../../../../../../../services/styled-components/variables";
import { rtl } from "services/styled-components/rtl";

export const Wrapper = styled.div`
    position: absolute;
    top: 1.4rem;
    left: 4.5rem;
    ${media.mdMax`
        top: 1.1rem;
        left: 3.7rem;
    `}
`;

export const Select = styled.select`
    display: none;
`;

interface IProps {
    active?: boolean;
}
export const Selected = styled.div<IProps>`
    width: 3.4rem;
    height: 2rem;
    position: relative;
    color: black;
    cursor: pointer;
    &::after {
        position: absolute;
        content: "";
        ${rtl`right: 0;`};
        height: 0.6rem;
        width: 1rem;
        background-image: url("${Arrow}");
        top: 0.8rem;
        ${({ active }) => {
            return active ? "transform: rotate(180deg);" : "";
        }}
    }
`;

export const ItemsWrapper = styled.div<IProps>`
    position: absolute;
    background: ${blockBgColor};
    border-radius: 2rem;
    padding: 1.5rem 0;
    z-index: 1;
    overflow: hidden;
    width: 23rem;
    top: 3.8rem;
    left: -4.4rem;
    display: ${({ active }) => (active ? "block" : "none")};
    ${media.mdMax`
        top: 3.1rem;
    `}
`;
