import React, { useEffect, useRef, useState } from "react";
import { WrapperSC, ArrowImageSC } from "./styled";
import arrowUpIconSrc from "../../assets/images/arrow-up.svg";

const DARK_LAYOUT_ID = "canEarn";

export const GoToTopButton = () => {
    const [visible, setVisible] = useState(false);
    const [isLightBackground, setIsLightBackground] = useState(true);
    const buttonToTopRef = useRef<HTMLDivElement>();

    useEffect(() => {
        // изменение цвета хедера при попадании на темные блоки
        // изменение цвета кнопки прокрутки при наведении на оранжевый фон
        const handleScroll = () => {
            const canEarnNode = document?.getElementById(DARK_LAYOUT_ID);

            if (buttonToTopRef.current && canEarnNode) {
                const canEarnNodeTop = canEarnNode.getBoundingClientRect().top;
                const canEarnNodeBottom =
                    canEarnNode.getBoundingClientRect().bottom;
                const buttonToTopNodeTop =
                    buttonToTopRef.current.getBoundingClientRect().top;

                setIsLightBackground(
                    canEarnNodeTop <= buttonToTopNodeTop &&
                        canEarnNodeBottom >= buttonToTopNodeTop,
                );
            }
        };

        const toggleVisible = () => {
            const scrolled = document.documentElement.scrollTop;
            setVisible(scrolled > 300);
        };

        document.addEventListener("scroll", handleScroll);
        window.addEventListener("scroll", toggleVisible);

        return () => {
            document.removeEventListener("scroll", handleScroll);
            window.removeEventListener("scroll", toggleVisible);
        };
    }, []);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    return (
        <WrapperSC
            onClick={scrollToTop}
            visible={visible}
            isLightBackground={isLightBackground}
            as={WrapperSC}
            ref={buttonToTopRef}
        >
            <ArrowImageSC src={arrowUpIconSrc} />
        </WrapperSC>
    );
};
