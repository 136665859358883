import { useFormikConsoleErrors } from "components/formik/use-formik-console-errors";
import { useTranslation } from "react-i18next";
import { useResetPassword } from "hooks/reset-password/use-reset-password";
import { CustomInput } from "../../../../components/custom-input";
import HumanReset from "../../../../assets/images/registration/humanReset.svg";
import { Caption, AuthWrapper, H1, Img } from "./styled";
import { RootHelmetAndScriptsBodyInjector } from "components/root-helmet";
import { metaData } from "../../index";
import { AuthButton } from "../styled";

const initialValues = { login: "", email: "" };

export const ForgotPassword = () => {
    const reset = useResetPassword();
    const [__] = useTranslation();
    const formik = useFormikConsoleErrors<typeof initialValues>({
        initialValues,
        onSubmit: reset.onSubmit,
        validationSchema: reset.validationSchema,
        enableReinitialize: true,
    });

    return (
        <>
            <RootHelmetAndScriptsBodyInjector
                titleText={format(
                    __(
                        "Восстановление пароля в партнерской программе {CompanyName}",
                    ),
                    metaData,
                )}
                metaText={format(
                    __(
                        "Забыли пароль в партнерской программе {CompanyName}? Не беспокойтесь, мы поможем вам его восстановить. Последуйте инструкциям на этой странице и получите доступ к своему аккаунту вновь",
                    ),
                    metaData,
                )}
            />
            <AuthWrapper>
                <Img src={HumanReset} />
                <form onSubmit={formik.handleSubmit}>
                    <H1>{__("Вы забыли пароль?")}</H1>
                    <Caption>
                        {reset.resetPassResponse.message
                            ? __(
                                  "На вашу почту была отпралена ссылка для сброса пароля. Проверьте свою почту, пожалуйста",
                              )
                            : __("Укажите свой e-mail и мы пришлем вам новый")}
                    </Caption>
                    {!reset.resetPassResponse.message && (
                        <>
                            <CustomInput
                                touched={formik.touched.login}
                                errors={formik.errors.login}
                                name="login"
                                type="text"
                                placeholder={`${__("Имя пользователя")}`}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.login}
                                required
                            />
                            <CustomInput
                                touched={formik.touched.email}
                                errors={formik.errors.email}
                                name="email"
                                id="email"
                                placeholder="Email"
                                type="text"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.email}
                                required
                            />

                            <AuthButton type="submit">
                                {__("Восстановить пароль")}
                            </AuthButton>
                        </>
                    )}
                </form>
            </AuthWrapper>
        </>
    );
};
