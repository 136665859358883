import styled from "styled-components";
import {
    accentColor,
    secondTextColor,
} from "../../../../../../services/styled-components/variables";

export const Path = styled.path`
    fill: ${secondTextColor};
    fill-opacity: 0.5;
    cursor: pointer;
    &:hover {
        fill: ${accentColor};
        fill-opacity: 1;
    }
`;
