import styled from "styled-components";
import { media } from "services/styled-components/variables";
import { secondTextColor } from "../../../../services/styled-components/variables";
import { FormCSS } from "../styled";

export const AuthWrapper = styled.div`
    min-height: 67rem;
    position: relative;
    overflow: hidden;
    ${media.smMax`
        min-height: calc(80vw + 35rem);
    `}
    & form {
        ${FormCSS};
    }
`;

export const Img = styled.img`
    position: absolute;
    left: 97rem;
    top: 3rem;
    z-index: -1;
    ${media.xlMax`
        top: 14rem;
        width: 65rem;
        right: 0;
        left: auto;
    `}
    ${media.lgMax`
        left: auto;
        right: -5rem;
    `}
    ${media.mdMax`
        left: auto;
        right: -20rem;
    `}
    ${media.smMax`
        top: auto;
        bottom: 10rem;
        width: 105vw;
        right: 30%;
        transform: translateX(50%);
    `}
`;

export const H1 = styled.h1`
    font-weight: 700;
    font-size: calc((100vw - 32rem) / (1920 - 320) * (50 - 20) + 2rem);
    line-height: 120%;
    color: ${secondTextColor};
    margin-top: 0;
    margin-bottom: 1rem;
    text-transform: uppercase;
    text-align: left;
    ${media.mdMax`
        margin-bottom: 0;
    `}
`;

export const Caption = styled.p`
    margin: 0;
    font-size: calc((100vw - 32rem) / (1920 - 320) * (20 - 14) + 1.4rem);
    color: ${secondTextColor};
    max-width: 32rem;
    @media (min-width: 1200px) {
        max-width: 54rem;
    }

    ${media.smMax`
        text-align: center;
        width: 80%;
    `}
`;
