import React, { useState } from "react";
import { useSignUp } from "hooks/sign-up/use-sign-up";
import * as yup from "yup";
import { useTranslationList } from "hooks/use-translations-list";
import { FormikProps } from "formik";
import {
    SignUpValues,
    useInitialValues,
} from "hooks/sign-up/use-initial-values";
import registrationSrc1 from "../../../../../../assets/images/registration/humanReg1.svg";
import registrationSrc2 from "../../../../../../assets/images/registration/humanReg2.svg";
import registrationSrc3 from "../../../../../../assets/images/registration/humanReg3.svg";
import { FirstStep } from "../registration-steps/first-step";
import { SecondStep } from "../registration-steps/second-step";
import { ThirdStep } from "../registration-steps/third-step";
import { useFormikConsoleErrors } from "components/formik/use-formik-console-errors";
import { createWebsiteRule } from "services/validators";
import { useMessengersInForms } from "hooks/use-messenger-login-validation";
import { idNameObject } from "validation/validaion";
import { moveToBegin } from "./helpers";
import { ISignUpForm } from "..";
import { useCountriesWithTranslations } from "hooks/query/query-with-translations/use-countries-with-translations";
import { touchStepFieldsAndCheckError } from "components/components-common/steps-form";
import { useCheckEmailFree } from "hooks/sign-up/use-check-email-free";
import { useCheckLoginFree } from "hooks/sign-up/use-check-login-free";
import { usePaymentSystemsForRegistration } from "hooks/sign-up/use-payment-systems-registration";
import { PaymentSystemType } from "hooks/query/use-payment-systems";
import { TReCAPTCHA } from "components/recaptcha";

export type TFormikProps = FormikProps<SignUpValues>;

interface IStepData {
    keys: Array<keyof ReturnType<typeof useInitialValues>>;
    bannerSrc: string;
    component: React.ReactNode;
}

export interface IRegistrationStep {
    formik: TFormikProps;
}

export const useStepData = ({
    setStage,
    recaptchaRef,
}: ISignUpForm & {
    recaptchaRef: React.RefObject<TReCAPTCHA>;
}) => {
    const { messengerOptions, getMessengerLoginValidation } =
        useMessengersInForms();
    const { INVALID_FORMAT, FIELD_SHOULD_BE_FILLED } = useTranslationList();
    const [startOfServerErrorsStage, setStartOfServerErrorsStage] =
        useState(false);
    const [step, setStep] = useState(0);
    const [freeEmailMessage, setFreeEmailMessage] = useState<
        string | undefined
    >();
    const [freeLoginMessage, setFreeLoginMessage] = useState<
        string | undefined
    >();

    const { initialValues, validationSchema, createSubmit } = useSignUp(
        setStage,
        {
            website: createWebsiteRule(INVALID_FORMAT).nullable().trim(),
            websiteCategory: yup
                .number()
                .nullable()
                .required(FIELD_SHOULD_BE_FILLED),
            messenger: idNameObject()
                .nullable()
                .required(FIELD_SHOULD_BE_FILLED),
            howDidYouKnow: yup.number().nullable(),
        },
    );

    const checkEmailIsFree = useCheckEmailFree();
    const checkLoginIsFree = useCheckLoginFree();

    const initialMessenger = messengerOptions.find(
        el => el.value.name === "Skype",
    );
    if (initialMessenger) {
        initialValues.messenger = initialMessenger.value;
    }
    // проставляем по умолчанию согласие на рассылку
    initialValues.mailingConfirmed = true;

    if (initialValues.paymentSystem) {
        initialValues.paymentSystem.fields[0].value = "000000";
    }

    const formik = useFormikConsoleErrors<typeof initialValues>({
        initialValues,
        onSubmit: async (...args) => {
            try {
                args[0].website = args[0].website || "http://example.com";
                const onSubmit = createSubmit(recaptchaRef.current);
                await onSubmit(...args);
            } catch (ex) {
                setStartOfServerErrorsStage(true);
            }
        },
        validationSchema,
        enableReinitialize: true,
        validate: values => {
            const errors = {} as Record<string, string | undefined>;

            validatePaymentSystemField(values.paymentSystem, errors);

            const messengerLoginErrorMsg = getMessengerLoginValidation(
                values.messenger?.id,
                values.messengerLogin,
            );
            if (messengerLoginErrorMsg) {
                errors.messengerLogin = messengerLoginErrorMsg;
            }

            if (freeEmailMessage) {
                errors.email = freeEmailMessage;
            }

            if (freeLoginMessage) {
                errors.login = freeLoginMessage;
            }

            return errors;
        },
    });

    const { getOptions, validatePaymentSystemField } =
        usePaymentSystemsForRegistration();
    const paymentSystemOptions = getOptions(initialValues.country);

    const { countries } = useCountriesWithTranslations();

    // плейер аккаунт должен быть первым в списке платежных систем
    if (paymentSystemOptions.length > 0) {
        const indexPlayerAccount = paymentSystemOptions.findIndex(
            (el: any) => el.value === PaymentSystemType.PlayerAccount,
        );
        moveToBegin(paymentSystemOptions, indexPlayerAccount);
    }

    const components = {
        firstStep: <FirstStep formik={formik} />,
        secondStep: (
            <SecondStep
                formik={formik}
                countries={countries}
                paymentSystemOptions={paymentSystemOptions}
            />
        ),
        thirdStep: <ThirdStep formik={formik} />,
    };

    const stepData: IStepData[] = [
        {
            keys: [
                "firstname",
                "surname",
                "email",
                "messenger",
                "messengerLogin",
            ],
            bannerSrc: registrationSrc1,
            component: components.firstStep,
        },
        {
            keys: [
                "websiteCategory",
                "website",
                "country",
                "phone",
                "paymentSystem",
                "language",
            ],
            bannerSrc: registrationSrc2,
            component: components.secondStep,
        },
        {
            keys: [
                "login",
                "password",
                "confirmPassword",
                "rulesConfirmed",
                "mailingConfirmed",
            ],
            bannerSrc: registrationSrc3,
            component: components.thirdStep,
        },
    ];

    if (startOfServerErrorsStage) {
        let minErrorPage: number | null = null;
        for (
            let stepDataIndex = 0;
            stepDataIndex < stepData.length;
            stepDataIndex++
        ) {
            for (const key of stepData[stepDataIndex].keys) {
                if (
                    formik.errors[key] &&
                    formik.touched[key] &&
                    (minErrorPage === null || stepDataIndex < minErrorPage)
                ) {
                    minErrorPage = stepDataIndex;
                }
            }
        }
        if (minErrorPage !== null) {
            setStep(minErrorPage);
        }
        setStartOfServerErrorsStage(false);
    }

    async function handleNextStep(nextStep: number) {
        if (!formik.dirty) {
            return;
        }

        const emailErrorMsg = await checkEmailIsFree(formik.values.email);
        setFreeEmailMessage(emailErrorMsg);

        let loginErrorMsg;
        if (nextStep === 3) {
            loginErrorMsg = await checkLoginIsFree(formik.values.login);
            setFreeLoginMessage(loginErrorMsg);
        }

        const hasError = await touchStepFieldsAndCheckError(
            stepData[step].keys,
            formik,
        );
        if (hasError || emailErrorMsg || loginErrorMsg) {
            return;
        }
        setStep(nextStep);
    }

    function handlePrevStep(prevStep: number) {
        setStep(prevStep);
    }

    return { stepData, formik, handleNextStep, handlePrevStep, step };
};
