import { NotoSansFonts } from "assets/fonts";
import { createGlobalStyle } from "styled-components";
import {
    StyledClose,
    StyledInner,
    StyledWrapper,
} from "components/components-common/modal/styled";
import { secondTextColor, backgroundColor, accentColor } from "./variables";
import { media } from "services/styled-components/variables";
/* stylelint-disable no-descending-specificity */
import { ReactSlick } from "./react-slick";
import toust from "../../assets/images/toustBG.png";

export const GlobalStyle = createGlobalStyle`
  html {
    ${NotoSansFonts};
    ${ReactSlick};
    font-family: "NotoSans", sans-serif;
    line-height: 1.65;
    font-size: 10px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    padding: 0;
  }

  a {
    text-decoration: none;
  }

  & ${StyledWrapper} { 
    & ${StyledInner} {
        max-width: 138rem;
        width: 100%;
        margin: 0;
        padding: 0;
        background: transparent;

        & a {
            text-decoration: none;
        }
        
        ${media.smMax`
            max-width: 100%;
           height: calc(100vh - 6rem);
        `}
    }

    & ${StyledClose} {
        width: 4rem;
        height: 4rem;
        background: #fff;
        border-radius: 50%;
        top: 1.8rem;
        right: 1.8em;
        transition: all 0.2s;

        &:hover {
            background: ${backgroundColor};
            color: #fff;
            svg {
                color: #fff;
            }
        }

        svg {
           color: ${secondTextColor};
        }

        ${media.smMax`
            top: 1.6rem;
            right: 50%;
            margin-right: -2rem;
        `}
    }
  }

 &:has(#sendForm) {
    .Toastify__toast.Toastify__toast--success {
    background-color: ${accentColor};
    background-image: url(${toust});
    background-size: contain;
    border-radius: 3rem;
    height: 20rem;
    width: 70rem;
    color: #FFF;
    text-align: center;
    font-size: 5rem;
    font-weight: 700;
    line-height: 120%;
    position: relative;
    margin-left: -19rem;

    ${media.lgMax`
        width: auto;
        height: 7.6rem;
        margin-left: 0;
        font-size: 2rem;
        line-height: 2.4rem;
        overflow: visible;
    `}
}

.Toastify__progress-bar.Toastify__progress-bar--animated.Toastify__progress-bar--success {
    ${media.lgMax`
        width: 90%;
        left: 3rem;
        height: 0.3rem;
    `}
   
}

button.Toastify__close-button.Toastify__close-button--success {
    position: absolute;
    top: 2rem;
    right: 2rem;
    border-radius: 50%;
    background: #c1c1c1;
    width: 4rem;
    height: 4rem;
    color: #1f1f1f;
 
    ${media.lgMax`
        top:9.6rem;
        left: 50%;
        margin-left: -2rem;
    `}
}
  }

  
`;
