import styled from "styled-components";
import { media } from "services/styled-components/variables";
import {
    backgroundColor,
    gradientColor,
    gradientBackgraund,
} from "../../../services/styled-components/variables";

export const Wrapper = styled.div`
    font-size: 1.6em;
    height: 12.8em;
    padding: 0 15%;
    background-color: ${backgroundColor};
    display: flex;
    justify-content: space-between;
    align-items: center;
    ${media.lgMax`
        padding: 0 10%;
    `}
    ${media.mdMax`
        padding: 0;
        height: auto;
        flex-direction: column-reverse;
        justify-content: center;
        gap: 2rem;
    `}
`;

export const Copyright = styled.p`
    width: 13em;
    font-size: 0.75em;
    line-height: 1.33em;
    opacity: 0.5;
    ${media.mdMax`
        text-align: center;
        order: -1;
    `}
`;

export const Android = styled.a`
    border: 0.1rem solid #3d3d3e;
    border-radius: 5rem;
    height: 4.8rem;
    width: 21.5rem;
    padding-left: 1.5rem;
    box-sizing: border-box;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    margin-bottom: 1.8rem;
    color: #ffffff;
    user-select: none;
    cursor: pointer;
    &:hover {
        background: ${gradientBackgraund};
    }
    &:active {
        background: ${gradientColor};
    }
    ${media.mdMax`
        height: 2.6rem;
        width: 11.9rem;
        font-size: 1.1rem;
        margin-bottom: 0;
        margin-right: 10rem;
        padding-left: 0.83rem;
    `}
`;

export const AndroidLogo = styled.div`
    margin-right: 1rem;
    color: #7a8188;
    margin-top: 0.2rem;
    width: 2.4rem;
    /* stylelint-disable */
    ${Android}:hover > & {
        color: #ffffff;
    }
    /* stylelint-enable */
    ${media.mdMax`
        width: 1.3rem;
        margin-right: 0.5rem;
        margin-top: 0.3rem;
    `}
`;
