import { WrapperInfoIcon, Hint, WrapperInfo, CloseBtn } from "./styled";
import { InfoIcon } from "./info-icon";
import { useState } from "react";

interface IInfo {
    hint: string;
    hintForSelect?: boolean;
}
export const Info = ({ hint, hintForSelect }: IInfo) => {
    const [isOpen, setIsOpen] = useState(false);
    return (
        <WrapperInfo hintForSelect={hintForSelect}>
            <WrapperInfoIcon onClick={() => setIsOpen(!isOpen)}>
                <InfoIcon />
            </WrapperInfoIcon>
            <Hint isSmall={hint.length < 100} isOpen={isOpen}>
                {hint}
                <CloseBtn onClick={() => setIsOpen(!isOpen)} />
            </Hint>
        </WrapperInfo>
    );
};
