import React from "react";
import { AllPosts } from "./all-posts";
import { RouteComponentProps, Switch } from "react-router";
import { PageRoute } from "components/check-roles/guard";
import { NotFoundHandler } from "components/not-found-handler";
import { CurrentPost } from "./current-post";
import urljoin from "url-join";

interface IProps extends RouteComponentProps<{ url: string }> {}

export const Blog = React.memo((props: IProps) => {
    const { url } = props.match;

    return (
        <Switch>
            <PageRoute path={urljoin(url)} component={AllPosts} />
            <PageRoute path={urljoin(url, ":name")} component={CurrentPost} />
            <PageRoute component={NotFoundHandler} path="*" />
        </Switch>
    );
});
