import { SignInType } from "hooks/sign-in/use-sign-in";
import { ReCAPTCHA, TReCAPTCHA } from "components/recaptcha";
import { useRef } from "react";
import { useFormikConsoleErrors } from "components/formik/use-formik-console-errors";
import { CustomInput } from "../../../../../components/custom-input";
import { CustomPassword } from "../../components/custom-password";
import { useTranslation } from "react-i18next";
import HumanAuthImg from "../../../../../assets/images/registration/humanAuth.svg";
import { Caption, AuthWrapper, H1, Img, LinksBlock } from "./styled";
import { AuthButton, RouterLink } from "../../styled";
import { RootHelmetAndScriptsBodyInjector } from "components/root-helmet";
import { metaData } from "../../../index";

interface IProps {
    login: SignInType["login"];
}

export const SignInForm = (props: IProps) => {
    const [__] = useTranslation();
    const recaptchaRef = useRef<TReCAPTCHA>(null);
    const formik = useFormikConsoleErrors<typeof props.login.initialValues>({
        initialValues: props.login.initialValues,
        onSubmit: async (...args) => {
            const onSubmit = props.login.createOnSubmit(recaptchaRef.current);
            await onSubmit(...args);
        },
        validationSchema: props.login.validationSchema,
        enableReinitialize: true,
    });

    return (
        <>
            <RootHelmetAndScriptsBodyInjector
                titleText={format(
                    __("Вход в партнерскую программу {CompanyName}"),
                    metaData,
                )}
                metaText={format(
                    __(
                        "Авторизуйтесь в партнерской программе {CompanyName} и начните зарабатывать с нами. Введите свои данные для входа на нашем сайте и получите доступ к своему аккаунту",
                    ),
                    metaData,
                )}
            />
            <AuthWrapper>
                <Img src={HumanAuthImg} />
                <form onSubmit={formik.handleSubmit}>
                    <H1>{__("Вход")}</H1>
                    <Caption>
                        {__("Проверьте, сколько вы заработали сегодня")}
                    </Caption>

                    <CustomInput
                        touched={formik.touched.login}
                        errors={formik.errors.login}
                        name="login"
                        id="login"
                        type="text"
                        placeholder={__("Имя пользователя")}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.login}
                        required
                    />
                    <CustomPassword
                        touched={formik.touched.password}
                        errors={formik.errors.password}
                        name="password"
                        id="password"
                        placeholder={__("Пароль")}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.password}
                        showPasswordTip
                        required
                    />
                    <AuthButton type="submit">{__("Войти")}</AuthButton>
                    <LinksBlock>
                        <RouterLink to="/forgot-password">
                            {__("Забыли пароль?")}
                        </RouterLink>
                        <RouterLink to="/sign-up">
                            {__("Регистрация")}
                        </RouterLink>
                    </LinksBlock>
                    <ReCAPTCHA ref={recaptchaRef} size="invisible" />
                </form>
            </AuthWrapper>
        </>
    );
};
