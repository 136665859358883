import styled, { css } from "styled-components";
import { media } from "services/styled-components/variables";
import {
    accentColor,
    secondTextColor,
} from "../../../../services/styled-components/variables";

const TextCSS = css`
    font-size: calc((100vw - 32rem) / (1920 - 320) * (18 - 12) + 12px);
    line-height: 2.52rem;
    color: ${secondTextColor};
    margin: 0;

    ${media.mdMax`
        line-height: 1.68rem;
    `}
`;

export const Wrapper = styled.div`
    padding-top: 18rem;
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-left: 10%;

    ${media.mdMax`
        margin-left: 0;
        width: auto;
        padding: 3rem;
        flex-direction: column;
        align-items: center;
    `}
`;

export const Title = styled.h1`
    font-weight: 700;
    font-size: calc((100vw - 32rem) / (1920 - 320) * (50 - 20) + 20px);
    line-height: 6rem;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: ${accentColor};
    margin: 0;
    margin-bottom: 2rem;

    ${media.mdMax`
        text-align: center;
        line-height: 2.4rem;
        margin-bottom: 3rem;
    `}
`;

export const Banner = styled.img`
    position: relative;
    margin-left: -10%;
    right: 10%;

    ${media.lgMax`
        width: 55rem;
    `}

    ${media.mdMax`
        right: 0;
        margin-left: 0;
        margin-top: 5.4rem;
        width: 130%;
    `}
`;

export const Paragraph = styled.p`
    ${TextCSS};
    width: 80%;

    ${media.mdMax`
        width: 100%;
    `}
`;

export const Link = styled.a`
    ${TextCSS};
    color: ${accentColor};
    &:hover {
        text-decoration: underline;
    }
`;

export const Content = styled.div`
    z-index: 1;
`;
