import { media } from "services/styled-components/variables";
import styled from "styled-components";
import {
    blockBgColor,
    accentColor,
    gradientBackgraund,
    gradientColor,
    secondTextColor,
} from "../../../../../services/styled-components/variables";
import { rtl } from "services/styled-components/rtl";

/* stylelint-disable */

export const RegistrationWrapper = styled.div`
    width: calc(100% - 765px);
    min-height: 71.2rem;
    min-width: 826px;
    display: flex;
    flex-direction: column;
    align-items: ${props =>
        props.theme.dir === "rtl" ? "flex-start" : "flex-end"};
    margin-top: 7rem;
    margin-right: auto;
    ${media.xlMax`
        width: calc(100% - 552px);
        align-items: center;
        min-width: 826px;
        min-height: 57rem;
    `}
    @media (max-width: 1182px) {
        width: calc(100% - 365px);
        min-width: 471px;
    }
    ${media.smMax`
        width: 100%;
        min-width: 100%;
        align-items: center;
        min-height: 40rem;

        margin-top: 2rem;
    `}
    @media (min-width: 2222px) {
        align-items: center;
    }
    & form {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 79.4rem;
        z-index: 0;
        ${media.lgMax`
            scale: 0.8;
        `}
        @media (max-width: 1182px) {
            scale: 0.7;
        }
        ${media.mdMax`
            width: 59.6rem;
        `}
        ${media.smMax`
            scale: 1;
        `}
        ${media.ltMax`
            width: 100%;
        `}
    }
`;

export const FormWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    ${media.ltMax`
        flex-direction: column;
        align-items: center;
        gap: 1.6rem;
    `}
`;

export const FormColumn = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 38.7rem;
    position: relative;

    ${media.mdMax`
        width: 28.8rem;
    `}
    ${media.ltMax`
        align-items: center;
    `}
    ${media.ltMax`
        gap: 1.6rem;
    `}
`;

export const FormWrapperForThirdStep = styled(FormWrapper)`
    flex-direction: column;
    ${media.smMax`
        align-items: center;
    `}
`;

interface IBanner {
    step: number;
}

export const Banner = styled.img<IBanner>`
    position: absolute;
    right: 0;
    top: ${({ step }) => (step === 1 ? "44px" : "auto")};
    ${media.xlMax`
       width: 550px;
    `}
    ${media.lgMax`
        width: calc(550px * 0.8);
        top: ${({ step }: IBanner) => (step === 1 ? "93px" : "auto")};
    `}
    @media (max-width: 1182px) {
        width: calc(550px * 0.7);
        top: ${({ step }: IBanner) => (step === 1 ? "180px" : "auto")};
    }
    ${media.mdMax`
        top: ${({ step }: IBanner) => (step === 1 ? "93px" : "auto")};
    `}
    ${media.smMax`
       display: none;
    `}
`;

export const Title = styled.h1`
    font-weight: 700;
    font-size: 5rem;
    font-size: calc((100vw - 32rem) / (1920 - 320) * (50 - 20) + 2rem);
    line-height: 120%;
    color: ${secondTextColor};
    margin-top: 0;
    margin-bottom: 1rem;
    text-transform: uppercase;
    ${rtl`text-align: left;`};
    width: 100%;
    ${media.mdMax`
        margin-bottom: 0;
    `}
    ${media.ltMax`
        text-align: center;
    `}
`;

export const Caption = styled.p`
    margin-bottom: 2rem;
    font-size: calc((100vw - 32rem) / (1920 - 320) * (16 - 14) + 1.4rem);
    color: ${secondTextColor};
    width: 100%;
    box-sizing: border-box;
    ${media.ltMax`
        text-align: center;
        padding: 0 2rem;
    `}
`;

export interface IProps {
    isActiveDote?: boolean;
}

export const Pagination = styled.div`
    ul {
        width: 5rem;
        margin: 2.2em auto;
        display: flex;
        justify-content: space-between;
    }
`;

export const Dote = styled.a<IProps>`
    width: 1rem;
    height: 1rem;
    background: ${(props: IProps) => {
        return props.isActiveDote ? accentColor : blockBgColor;
    }};
    border-radius: 50%;
    display: block;
`;

export const ButtonsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    justify-content: center;

    ${media.ltMax`
        flex-direction: column;
        gap: 0;
    `}
`;

export const RegistrationButton = styled.div`
    width: 38.6rem;
    height: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 1.6rem;
    color: #ffffff;
    line-height: 120%;
    text-transform: uppercase;
    background: ${accentColor};
    border-radius: 2.5rem;
    border: none;
    margin-bottom: 2rem;
    cursor: pointer;
    ${media.mdMax`
        width: 28.8rem;
        height: 4rem;
        font-size: 1.2rem;
    `}
    &:hover {
        background: ${gradientBackgraund};
    }
    &:active {
        background: ${gradientColor};
    }
`;

export const Label = styled.label`
    font-size: 1.4rem;
    color: rgba(24, 24, 25, 0.5);
    background: rgb(255, 255, 255);
    position: absolute;
    top: 4.4rem;
    inset-inline-start: 3.1rem;
    padding: 0 0.5rem;
    z-index: 1;

    ${media.lt`
        top: 4.8rem;
        inset-inline-start: 2.5rem;
        padding: 0 0.5rem 0 1rem;
    `}

    ${media.md`
        font-size: 1.6rem;
        top: 6rem;
        inset-inline-start: 3.1rem; 
    `}

    ::before {
        display: none;

        ${media.lt`
            content: "*";
            position: absolute;
            font-size: 1.6rem;
            inset-inline-start: 0;
            color: ${accentColor};
            display: block;
            font-size: 1.4rem;
        `}
    }
`;
