import { Form } from "views/irregular-landings/57/styled/layouts";
import { Wrapper, Container, SendButton, Input } from "./styled";
import { useTranslation } from "react-i18next";
import { useModalState } from "hooks/use-modal-state";
import { SignInType } from "hooks/sign-in/use-sign-in";
import { useFormikConsoleErrors } from "components/formik/use-formik-console-errors";
import { useEffect } from "react";
import { ModalSize } from "components/components-common/modal/modal-size";
import { ModalNoStyles } from "components/components-common/modal";

type TwoFaModalProps = SignInType["_2fa"] & {
    onClose: () => void;
};

export function TwoFaModal(props: TwoFaModalProps) {
    const [__] = useTranslation();
    const { initialValues, onSubmit, validationSchema } = props;
    const { modalProps, open } = useModalState({
        size: ModalSize.Small,
    });
    const formik = useFormikConsoleErrors<typeof initialValues>({
        initialValues,
        onSubmit,
        validationSchema,
        enableReinitialize: true,
    });

    useEffect(() => {
        open();
    }, [open]);

    return (
        <ModalNoStyles {...modalProps} onRequestClose={() => props.onClose()}>
            <Wrapper>
                <Form onSubmit={formik.handleSubmit} id="2faForm">
                    <Container>
                        <Input
                            touched={formik.touched.code}
                            errors={formik.errors.code}
                            name="code"
                            type="text"
                            placeholder={`${__("Код")}`}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.code}
                            required
                        />
                        <SendButton type="submit">{__("Отправить")}</SendButton>
                    </Container>
                </Form>
            </Wrapper>
        </ModalNoStyles>
    );
}
