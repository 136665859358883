export const textColor = "#2d2d2d";
export const accentColor = "#db5318";
export const errorColor = "#F60000";
export const backgroundColor = "#202022";
export const gradientColor = "#fca259";
export const secondTextColor = "#181819";
export const blockBgColor = "#e9eef2";
export const borderColor = "#a7c3da";
export const hintColor = "#d9d9d9";
export const gradientBackgraund = `linear-gradient(90deg, ${accentColor} 0%, ${gradientColor} 100%)`;
