import styled from "styled-components";
import {
    accentColor,
    gradientColor,
    secondTextColor,
} from "../../../../services/styled-components/variables";
import howItWorkBackgroundSrc from "../../../../assets/images/work/how-it-work-bg.png";
import baseballBackgroungSrc from "../../../../assets/images/work/baseball-bg.png";
import { media } from "services/styled-components/variables";
import { LocalizedLink } from "components/language-provider";
import { Title } from "../../../../styled/layouts";

export const BackgroundWrapper = styled.div`
    background-image: url(${howItWorkBackgroundSrc});
    display: flex;
    background-size: cover;
    background-repeat: no-repeat;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    width: 100%;
`;

export const ExtraBackgroundWrapper = styled.div`
    background-image: url(${baseballBackgroungSrc});
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    width: 100%;
    height: 100%;
    background-position-y: 15em;

    ${media.mdMax`
        background-image: none;
    `}
`;
export const Container = styled.div`
    padding: 10.1em 0 13.4em;
    max-width: 120em;
    position: relative;

    ${media.lgMax`
        padding: 5rem 1.6rem;
    `}

    ${media.mdMax`
        max-width: auto;
    `}

    ${Title} {
        margin-bottom: 1.6rem;

        ${media.lgMax`
            margin-bottom: 1.2rem;
        `}
    }
`;

export const SubTitle = styled.h3`
    font-weight: 400;
    font-size: 2rem;
    line-height: 150%;
    text-align: center;
    color: ${secondTextColor};
    margin: 0 0 1.3em;

    ${media.lgMax`
        font-size: 1.2rem;
    `}
`;

export const Strikethrough = styled.s`
    display: block;
`;

export const StepsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto 17.1rem;
    gap: 2rem;
    width: 59rem;

    ${media.lgMax`
        margin-bottom: 3.3rem;
    `}

    ${media.mdMax`
        width: auto;
        gap: 0.8rem;
    `}
`;

export const StepBlock = styled.div`
    box-shadow: 0px 2em 20em rgba(32, 32, 34, 0.15);
    border-radius: 2em;
    background: #fff;
    padding: 3em;
    display: flex;
    align-items: center;
    gap: 1.4em;
    width: 100%;
    box-sizing: border-box;

    ${media.lgMax`
        padding: 1.6em;
    `}
`;
export const Count = styled.div`
    font-weight: 700;
    font-size: 12rem;
    color: ${accentColor};
    line-height: 0.74;

    ${media.lgMax`
        font-size: 6rem;
    `}
`;
export const StepDescription = styled.div`
    color: ${secondTextColor};
`;
export const StepTitle = styled.h4`
    font-weight: 700;
    font-size: 2rem;
    line-height: 120%;
    margin: 0 0 1.2rem;

    ${media.lgMax`
        margin: 0 0 1rem;
        font-size: 1.4rem;
    `}
`;
export const StepText = styled.p`
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 160%;
    margin: 0;

    ${media.lgMax`
        font-size: 1.2rem;
    `}
`;

export const StepLink = styled(LocalizedLink)`
    color: ${accentColor};
    text-decoration: underline;

    &:active,
    &:hover {
        color: ${gradientColor};
        border-bottom: 2px solid ${gradientColor};
        text-decoration: none;
    }
`;

export const GetMoneyBlock = styled.div``;

export const GetMoneyTitle = styled.div`
    font-weight: 700;
    font-size: 5rem;
    line-height: 120%;
    color: ${secondTextColor};
    text-align: center;
    margin-bottom: 1.2em;

    ${media.lgMax`
        font-size:1.6rem;
    `}
`;

export const GetMoneySubTitle = styled.span`
    margin: 0;
    white-space: nowrap;
    display: inline-block;

    ${media.mdMax`
        white-space: inherit;
        margin: 0 auto;
    `};
`;

export const GetMoneyUnderlinedTitle = styled.span`
    color: #fff;
    background: ${accentColor};
    padding: 0 0.3em;
`;

export const GetMoneyWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 2em;

    ${media.mdMax`
        flex-wrap: nowrap;
        flex-direction: column;
        gap: 0.8rem;
    `}
`;
export const GetMoneyItem = styled.div`
    background: #fff;
    border-radius: 2rem;
    padding: 3.1rem 2rem;
    display: flex;
    align-items: center;
    gap: 2rem;
    flex: 1 1 25%;
    position: relative;
    box-sizing: border-box;

    ${media.lgMax`
        padding: 1.8rem 2rem;
    `}

    ${media.mdMax`
        width: 100%;
    `}
`;
export const Icon = styled.img`
    position: relative;
    width: 10rem;
    height: 10.8rem;

    ${media.lgMax`
        width: 6.6rem;
        height: 7rem;
    `}
`;
export const GetMoneyDescription = styled.div`
    font-weight: 400;
    position: relative;
    font-size: 2rem;
    line-height: 120%;

    ${media.lgMax`
        font-size:1.2rem;
    `}
`;
