import { RouteComponentProps } from "react-router";
import { useTranslation } from "react-i18next";
import { useNewPassword } from "hooks/new-password/use-new-password";
import { useFormikConsoleErrors } from "components/formik/use-formik-console-errors";
import { RootHelmetAndScriptsBodyInjector } from "components/root-helmet";
import { CustomInput } from "../../../../components/custom-input";
import HumanAuth from "../../../../assets/images/registration/humanAuth.svg";
import { metaData } from "../../.";
import { AuthButton } from "../styled";
import { AuthWrapper } from "../forgot-password/styled";
import { NewPasswordImg, NewPasswordTitle } from "./styled";

export const NewPassword = (props: RouteComponentProps) => {
    const [__] = useTranslation();
    const search = new URLSearchParams(props.location.search);
    const id = search.get("id") || "";
    const { validationSchema, initialValues, onSubmit } = useNewPassword(id);

    const formik = useFormikConsoleErrors<typeof initialValues>({
        initialValues,
        onSubmit,
        validationSchema,
        enableReinitialize: true,
    });

    return (
        <>
            <RootHelmetAndScriptsBodyInjector
                titleText={format(
                    __(
                        "Восстановление пароля в партнерской программе {CompanyName}",
                    ),
                    metaData,
                )}
            />
            <AuthWrapper>
                <NewPasswordImg src={HumanAuth} />
                <form onSubmit={formik.handleSubmit}>
                    <NewPasswordTitle>
                        {__("Создать новый пароль")}
                    </NewPasswordTitle>
                    <CustomInput
                        touched={formik.touched.password}
                        errors={formik.errors.password}
                        name="password"
                        type="password"
                        placeholder={`${__("Новый пароль")}`}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.password}
                        required
                    />
                    <CustomInput
                        touched={formik.touched.rePassword}
                        errors={formik.errors.rePassword}
                        name="rePassword"
                        type="password"
                        placeholder={`${__("Подтвердите пароль")}`}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.rePassword}
                        required
                    />

                    <AuthButton type="submit">
                        {__("Изменить пароль")}
                    </AuthButton>
                </form>
            </AuthWrapper>
        </>
    );
};
