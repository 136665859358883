import { useTranslation } from "react-i18next";
import {
    Wrapper,
    Container,
    SubTitle,
    Description,
    SocialWrapper,
    Info,
    IconWrapper,
    Icon,
    Item,
    InfoTitle,
    InfoDescription,
    RightImage,
} from "./styled";
import { Title } from "../../../../styled/layouts";
import instaIconSrc from "../../../../assets/images/earn/insta.svg";
import tikTokIconSrc from "../../../../assets/images/earn/tiktok.svg";
import { SendForm } from "../../../../components/send-form";
import earnBg from "../../../../assets/images/earn/earn-bg.png";

export const CanEarn = React.memo(() => {
    const [__] = useTranslation();

    const socials = [
        {
            src: instaIconSrc,
            title: __("2786"),
            description: __("лидов привел из Instagram"),
        },
        {
            src: tikTokIconSrc,
            title: __("2466"),
            description: __("лидов привел из Tik Tok"),
        },
    ];

    return (
        <Wrapper id="canEarn">
            <RightImage src={earnBg} />
            <Container>
                <Title color="#fff">{__("Заработать может каждый!")}</Title>
                <SubTitle>
                    {__("И мы расскажем как это сделать ")}&#128521;
                </SubTitle>
                <Description>
                    {__(
                        "Мы не новички в том, что делаем. Под нашим наставничеством партнеры зарабатывают ежемесячно от 1000 долл. Мы собрали лучшие кейсы для вашего старта. Готовы начинать?",
                    )}
                </Description>
                <SendForm buttonType="submit" />
                <SocialWrapper>
                    {socials.map((social, index) => (
                        <Item key={`${social.title}_${index}`}>
                            <IconWrapper>
                                <Icon src={social.src} />
                            </IconWrapper>
                            <Info>
                                <InfoTitle>{social.title}</InfoTitle>
                                <InfoDescription>
                                    {social.description}
                                </InfoDescription>
                            </Info>
                        </Item>
                    ))}
                </SocialWrapper>
            </Container>
        </Wrapper>
    );
});
