import { Caption, FormWrapper } from "../styled";
import { CustomInput } from "../../../../../../components/custom-input";
import { IRegistrationStep } from "../hooks/use-step-data";
import { useTranslation } from "react-i18next";
import { Messenger } from "../messenger";

export const FirstStep = ({ formik }: IRegistrationStep) => {
    const [__] = useTranslation();

    return (
        <>
            <Caption>
                {__(
                    "Привет! Давайте познакомимся! Как вас зовут и как мы можем с вами связаться?",
                )}
            </Caption>
            <FormWrapper>
                <CustomInput
                    touched={formik.touched.firstname}
                    errors={formik.errors.firstname}
                    name="firstname"
                    id="firstname"
                    type="text"
                    placeholder={`${__("Имя")}`}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.firstname}
                    required
                />
                <CustomInput
                    touched={formik.touched.surname}
                    errors={formik.errors.surname}
                    name="surname"
                    id="surname"
                    type="text"
                    placeholder={`${__("Фамилия")}`}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.surname}
                    required
                />
                <CustomInput
                    touched={formik.touched.email}
                    errors={formik.errors.email}
                    name="email"
                    id="email"
                    placeholder="E-Mail"
                    type="text"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                    required
                />
                <Messenger formik={formik} />
            </FormWrapper>
        </>
    );
};
