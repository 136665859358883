import { FormikProps } from "components/formik";

export async function touchStepFieldsAndCheckError(
    stepsKeys: string[],
    formik: FormikProps<Record<string, unknown>>,
) {
    const touchedObj = stepsKeys.reduce((acc, el) => {
        acc[el] = true;
        return acc;
    }, formik.touched as Record<string, boolean>);
    const hasError = stepsKeys.reduce((acc, el) => {
        acc = acc || !!formik.errors[el];
        return acc;
    }, false);
    await formik.setTouched(touchedObj);
    return hasError;
}
