import styled from "styled-components";
import { media } from "services/styled-components/variables";
import { accentColor } from "../../../../services/styled-components/variables";
import {
    LanguageActive,
    LanguageActiveWrapper,
    LanguageSwitcherSC,
} from "components/components-common/language/language-switcher/styled";
import { StateOfLink } from "../../../../styled/layouts";
/* stylelint-disable no-descending-specificity */

interface ITextBlockWithColor {
    color: string;
}

export const ContainerLang = styled.div<ITextBlockWithColor>`
    display: flex;
    justify-content: center;
    ${LanguageSwitcherSC} {
        &:hover {
            ${LanguageActiveWrapper} {
                &:after {
                    background-color: ${accentColor};
                }
            }
            ${LanguageActive} {
                color: ${accentColor};
            }
        }
    }

    ${LanguageActiveWrapper} {
        &:after {
            background-color: ${props => props.color};
        }
    }

    ${LanguageActive} {
        color: ${props => props.color};
        ${media.mdMax`
            color: #fff;
        `}
        ${media.ltMax`
        display: flex;
        align-items: center;
        padding: 0;
            &:after {
                content: attr(data-title);
                margin-left: 5px;
           }
        `}
    }

    ul {
        flex-direction: column;
        max-width: 7rem;

        li a {
            border-bottom: 1px solid #000;
            border-right: 0;
        }
    }
`;

export const Navigation = styled.nav<ITextBlockWithColor>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 2.5em;
    font-size: 1.6em;

    & div {
        text-align: center;
        line-height: 1;
    }

    & a {
        cursor: pointer;
        color: ${props => props.color};
        ${StateOfLink}

        &.active {
            border-color: ${accentColor};
            color: ${accentColor};
        }
    }

    ${media.mdMax`
        order: 1;
    `}
`;

export const DesktopOnly = styled.div`
    ${media.mdMax`
        display: none;
    `}
`;
