import React from "react";
import { LocalizedNavLink } from "components/language-provider";
import { useTranslation } from "react-i18next";
import {
    FieldCheckRules,
    FormError,
    Input,
    CheckboxWrapper,
    Caption,
} from "./styled";
import { FormikProps } from "formik";

interface IFormikValues {
    rulesConfirmed: boolean;
    mailingConfirmed: boolean;
}

interface ICheckAgreements {
    buttonText?: string;
    linkColor?: string;
    formik: FormikProps<IFormikValues>;
    showConfirmMailing?: boolean;
}

export const CheckAgreements = React.memo((props: ICheckAgreements) => {
    const { buttonText, linkColor, formik, showConfirmMailing } = props;

    const [__] = useTranslation();
    const { touched, errors, values, setFieldValue } = formik;
    const companyName = "BizBet";

    const [mainPart, terms, and, privacy] = format(
        __(
            "Нажимая на кнопку {buttonText}, вы подтверждаете согласие с {separator}Условиями{separator} и {separator}Политикой конфиденциальности",
        ),
        {
            separator: "{separator}",
            buttonText,
        },
    ).split("{separator}");

    return (
        <>
            <CheckboxWrapper>
                <Input
                    name="rulesConfirmed"
                    type="checkbox"
                    id="rulesConfirmed"
                    onChange={event =>
                        setFieldValue("rulesConfirmed", event.target.checked)
                    }
                    checked={values.rulesConfirmed}
                />
                <FieldCheckRules
                    isValid={
                        !touched.rulesConfirmed ||
                        (touched.rulesConfirmed && !errors.rulesConfirmed)
                    }
                    htmlFor="rulesConfirmed"
                />
                <Caption linkColor={linkColor}>
                    {mainPart}
                    <LocalizedNavLink to="/terms">{terms}</LocalizedNavLink>
                    {and}
                    <LocalizedNavLink to="/privacy">{privacy}</LocalizedNavLink>
                    {touched.rulesConfirmed && errors.rulesConfirmed && (
                        <FormError>
                            {__("Необходимо принять условия")}
                        </FormError>
                    )}
                </Caption>
            </CheckboxWrapper>
            {showConfirmMailing && (
                <CheckboxWrapper>
                    <Input
                        name="mailingConfirmed"
                        type="checkbox"
                        id="mailingConfirmed"
                        onChange={event =>
                            setFieldValue(
                                "mailingConfirmed",
                                event.target.checked,
                            )
                        }
                        checked={values.mailingConfirmed}
                    />
                    <FieldCheckRules
                        htmlFor="mailingConfirmed"
                        isValid={!errors.mailingConfirmed}
                    />
                    <Caption>
                        {format(
                            __(
                                "Я согласен быть в курсе новостей от {companyName}",
                            ),
                            {
                                companyName,
                            },
                        )}
                    </Caption>
                </CheckboxWrapper>
            )}
        </>
    );
});
