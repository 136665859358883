import { Title } from "../../../../styled/layouts";
import {
    BlockWrapper,
    MainBlock,
    ItemWrapper,
    ItemImg,
    ItemTitle,
    ItemText,
    PeopleBlock,
    PeopleMobBlock,
} from "./styled";
import person1Src from "../../../../assets/images/people/person1.png";
import person2Src from "../../../../assets/images/people/person2.png";
import person3Src from "../../../../assets/images/people/person3.png";
import person4Src from "../../../../assets/images/people/person4.png";
import person5Src from "../../../../assets/images/people/person5.png";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import { ArrowIosForwardOutline } from "@styled-icons/evaicons-outline/ArrowIosForwardOutline";
import { ArrowIosBackOutline } from "@styled-icons/evaicons-outline/ArrowIosBackOutline";

export const People = () => {
    const [__] = useTranslation();
    const settings = {
        dots: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        buttons: true,
        nextArrow: <ArrowIosForwardOutline size="18" />,
        prevArrow: <ArrowIosBackOutline size="18" />,
    };

    const peopleSlides = [
        {
            src: person1Src,
            title: __("Владелец сайта"),
            description: __(
                "Имеете сайт по тематике Спорт, Казино, Крипто или Кибер",
            ),
        },
        {
            src: person2Src,
            title: __("Пользователь социальных сетей"),
            description: __(
                "Ведете страницу в социальных сетях: YouTube, Twitter, İnsatgram и др. ",
            ),
        },
        {
            src: person3Src,
            title: __("Арбитражник"),
            description: __(
                "Запускаете рекламу, работаете с сетками и приложениями",
            ),
        },
        {
            src: person4Src,
            title: __("Спортивный фанат"),
            description: __(
                "Любите спорт и делитесь прогнозами на ставки с друзьями",
            ),
        },
        {
            src: person5Src,
            title: __("Новичок в маркетинге"),
            description: __(
                "Хотите научиться зарабатывать на рекламе Интернете и не знаете, с чего начать",
            ),
        },
    ];

    return (
        <BlockWrapper>
            <MainBlock>
                <Title>
                    {format(
                        __(
                            "Вы созданы стать партнером {CompanyName}, если вы:",
                        ),
                        { CompanyName: "BizBet" },
                    )}
                </Title>
                <PeopleBlock>
                    {peopleSlides.map((peopleSlide, index) => (
                        <ItemWrapper key={`${peopleSlide.title}_${index}`}>
                            <ItemImg src={peopleSlide.src} />
                            <ItemTitle>{peopleSlide.title}</ItemTitle>
                            <ItemText>{peopleSlide.description}</ItemText>
                        </ItemWrapper>
                    ))}
                </PeopleBlock>
                <PeopleMobBlock>
                    <Slider {...settings}>
                        {peopleSlides.map((peopleSlide, index) => (
                            <ItemWrapper key={`${peopleSlide.title}_${index}`}>
                                <ItemImg src={peopleSlide.src} />
                                <ItemTitle>{peopleSlide.title}</ItemTitle>
                                <ItemText>{peopleSlide.description}</ItemText>
                            </ItemWrapper>
                        ))}
                    </Slider>
                </PeopleMobBlock>
            </MainBlock>
        </BlockWrapper>
    );
};
