import { MessengerWrapper, MessengerInput } from "./styled";
import { MessengerSelect } from "./messenger-select";
import { useMessengersInForms } from "hooks/use-messenger-login-validation";
import { useState } from "react";
import { SignUpValues } from "hooks/sign-up/use-initial-values";
import { FormikProps } from "formik";

interface IProps extends React.InputHTMLAttributes<HTMLInputElement> {
    formik: FormikProps<SignUpValues>;
}

export const Messenger = (props: IProps) => {
    const { messengerOptions, getPlaceholder } = useMessengersInForms();
    const [active, setActive] = useState(false);
    return (
        <MessengerWrapper
            onClick={() => {
                if (!props.formik.values.messenger) {
                    setActive(!active);
                }
            }}
        >
            <MessengerInput
                id="messengerLogin"
                name="messengerLogin"
                touched={
                    props.formik.touched.messenger ||
                    props.formik.touched.messengerLogin
                }
                errors={
                    props.formik.errors.messenger ||
                    props.formik.errors.messengerLogin
                }
                onChange={props.formik.handleChange}
                onBlur={props.formik.handleBlur}
                value={props.formik.values.messengerLogin}
                placeholder={getPlaceholder(props.formik.values.messenger?.id)}
                type="text"
                readOnly={!props.formik.values.messenger}
                required
            />
            <MessengerSelect
                id="messenger"
                name="messenger"
                active={active}
                setActive={setActive}
                touched={props.formik.touched.messenger}
                errors={props.formik.errors.messenger}
                onSelectItem={selectedItem =>
                    props.formik.setFieldValue("messenger", selectedItem.value)
                }
                onBlur={props.formik.handleBlur}
                items={messengerOptions}
                curItem={messengerOptions.find(
                    option => option.value === props.formik.values.messenger,
                )}
            />
        </MessengerWrapper>
    );
};
