export const EmailImg = () => (
    <svg
        width="19"
        height="13"
        viewBox="0 0 17 13"
        fill="#FFFFFF"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M15.4062 0.125C16.2861 0.125 17 0.838535 17 1.71875C17 2.22012 16.7643 2.6916 16.3625 2.99375L9.1375 8.4125C8.75898 8.69473 8.24102 8.69473 7.8625 8.4125L0.6375 2.99375C0.236174 2.6916 0 2.22012 0 1.71875C0 0.838535 0.713535 0.125 1.59375 0.125H15.4062ZM7.225 9.2625C7.98203 9.83027 9.01797 9.83027 9.775 9.2625L17 3.84375V10.75C17 11.9221 16.0471 12.875 14.875 12.875H2.125C0.95127 12.875 0 11.9221 0 10.75V3.84375L7.225 9.2625Z" />
    </svg>
);
