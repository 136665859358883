import styled from "styled-components";
import { CustomInput } from "../../../../../../components/custom-input";
import { media } from "services/styled-components/variables";

export const MessengerWrapper = styled.div`
    position: relative;
`;

export const MessengerInput = styled(CustomInput)`
    & input[type="text"] {
        padding-inline-start: 9rem;

        ${media.mdMax`
            padding-inline-start: 8rem;
        `}
    }
`;
