import styled from "styled-components";
import { media } from "services/styled-components/variables";
import { CustomInput } from "views/irregular-landings/57/components/custom-input";
import { FormError } from "views/irregular-landings/57/components/custom-input/styled";

export const Wrapper = styled.div`
    background: linear-gradient(45deg, #db5318, #fca259);
    color: #fff;
    border-radius: 2rem;
    font-size: 10px;
    line-height: 2.16rem;
    height: 29.2rem;
    padding: 8.6rem 4.1rem 0;
    box-sizing: border-box;
    z-index: 11;

    ${media.md`
        position: absolute;
        left: 12%;
        width: 61.4rem;
    `}
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    ${media.md`
        align-items: flex-start;
        
    `}
    gap: 4.6rem;
`;

export const SendButton = styled.button`
    font-size: 1.8rem;
    display: flex;
    width: 100%;
    box-sizing: border-box;
    min-width: 12.3rem;
    height: 4.7rem;
    background: transparent;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-weight: 700;
    color: #ffffff;
    border: 1px solid #ffffff;
    border-radius: 4rem;
    line-height: 2.16rem;
    cursor: pointer;
    padding: 0 2rem;

    ${media.md`
        width: fit-content;
        
    `}
`;

export const Input = styled(CustomInput)`
    ${FormError} {
        color: #fff;
        font-size: 1.2rem;
        margin-top: 0.33rem;
    }
`;
