import { useState } from "react";
import { PhoneWrapper, PhoneInput, Placeholder } from "./styled";
// import { CountrySelect } from "./country-select";
import { ICustomInput } from "../../../../../../components/custom-input";
import { ICountry } from "hooks/query/query-with-translations/use-countries-with-translations";
import { SignUpValues } from "hooks/sign-up/use-initial-values";
import { useCountriesWithTranslations } from "hooks/query/query-with-translations/use-countries-with-translations";
import { useLanguage } from "components/language-provider";
import { CustomSelect, IOption } from "../../../components/custom-select";
interface IProps extends ICustomInput {
    setFieldValue: (field: string, value: any) => void;
    values: SignUpValues;
}
export const Phone = ({ setFieldValue, values, ...props }: IProps) => {
    const { countries } = useCountriesWithTranslations();
    const {
        language: { flag },
    } = useLanguage();

    const initialPhoneCountry = countries.find(
        country => country.value.code === flag,
    );
    const [selectedPhoneCountry, setSelectedPhoneCountry] =
        useState(initialPhoneCountry);

    if (
        selectedPhoneCountry &&
        !values.phone.startsWith(`+${selectedPhoneCountry.value.phoneCode}`)
    ) {
        setFieldValue("phone", `+${selectedPhoneCountry.value.phoneCode}`);
    }
    const hasCustomPlaceholder =
        values.phone === `+${selectedPhoneCountry?.value.phoneCode}`;
    const lengthPhoneCode = selectedPhoneCountry?.value.phoneCode.length || 0;
    return (
        <PhoneWrapper>
            <PhoneInput
                {...props}
                placeholder={hasCustomPlaceholder ? "" : "Номер телефона"}
            />
            {hasCustomPlaceholder && (
                <Placeholder lengthPhoneCode={lengthPhoneCode}>
                    00 123 45 67
                </Placeholder>
            )}
            <CustomSelect
                items={countries}
                curItem={selectedPhoneCountry}
                onCurItemChange={(option: IOption) =>
                    setSelectedPhoneCountry(option as ICountry)
                }
                isCountrySelect
            />
        </PhoneWrapper>
    );
};
