import { accentColor } from "../../../../../../services/styled-components/variables";

interface IProps extends React.SVGProps<SVGSVGElement> {
    active?: boolean;
}

export const Eye = ({ active, ...props }: IProps) => {
    return (
        <svg
            width="14"
            height="10"
            viewBox="0 0 14 10"
            fill={active ? accentColor : "#A7C3DA"}
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M6.79584 2.86607C6.86389 2.85938 6.93195 2.85714 7 2.85714C8.2882 2.85714 9.33334 3.79688 9.33334 5C9.33334 6.18304 8.2882 7.14286 7 7.14286C5.68993 7.14286 4.66667 6.18304 4.66667 5C4.66667 4.9375 4.6691 4.875 4.67639 4.8125C4.90243 4.91295 5.16493 5 5.44445 5C6.30243 5 7 4.35938 7 3.57143C7 3.31473 6.90521 3.07366 6.79584 2.86607ZM11.6813 1.79911C12.8188 2.76786 13.5795 3.90848 13.9392 4.72545C14.0194 4.90179 14.0194 5.09821 13.9392 5.27455C13.5795 6.07143 12.8188 7.21205 11.6813 8.20089C10.5365 9.17857 8.96389 10 7 10C5.03611 10 3.46354 9.17857 2.31924 8.20089C1.18174 7.21205 0.421461 6.07143 0.059819 5.27455C-0.0199397 5.09821 -0.0199397 4.90179 0.059819 4.72545C0.421461 3.90848 1.18174 2.76786 2.31924 1.79911C3.46354 0.822321 5.03611 0 7 0C8.96389 0 10.5365 0.822321 11.6813 1.79911ZM7 1.78571C5.06771 1.78571 3.5 3.22545 3.5 5C3.5 6.77455 5.06771 8.21429 7 8.21429C8.93229 8.21429 10.5 6.77455 10.5 5C10.5 3.22545 8.93229 1.78571 7 1.78571Z"
                fill={active ? accentColor : "#A7C3DA"}
            />
        </svg>
    );
};
