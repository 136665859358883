import React from "react";
import { SignInForm } from "./sign-in-form";
import { RouteComponentProps } from "react-router";
import { useSignIn } from "hooks/sign-in/use-sign-in";
import _ from "lodash";
import { usePermissions } from "components/check-roles/use-permissions";
import { auth } from "components/check-roles/rules";
import { SignInStage } from "hooks/sign-in/stage/use-stage";
import { Loader } from "components/components-common/loaders/cub";
import {
    accentColor,
    textColor,
} from "../../../../services/styled-components/variables";
import { SignInHelmet } from "components/helmet/sign-in-helmet";
import { TwoFaModal } from "./two-fa-modal";

interface IProps extends RouteComponentProps<{ partnerId: string }> {}

export const SignIn = React.memo((props: IProps) => {
    const { checkPermissions } = usePermissions();
    const partnerId = props.match.params.partnerId
        ? _.parseInt(props.match.params.partnerId)
        : undefined;
    const { stageControl, login, _2fa } = useSignIn(partnerId);

    if (
        stageControl.stage === SignInStage.SIMPLE_SIGN_IN &&
        checkPermissions([auth])
    ) {
        stageControl.setStage(SignInStage.FINISH);
    }
    return (
        <>
            <SignInHelmet
                stage={stageControl.stage}
                setStage={stageControl.setStage}
            />
            {(() => {
                switch (stageControl.stage) {
                    case SignInStage.SIMPLE_SIGN_IN:
                    case SignInStage.SIMPLE_SIGN_IN_ERROR:
                    case SignInStage.TWO_FA:
                    case SignInStage.TWO_FA_ERROR:
                        return (
                            <>
                                <SignInForm login={login} />
                                {(stageControl.stage === SignInStage.TWO_FA ||
                                    stageControl.stage ===
                                        SignInStage.TWO_FA_ERROR) && (
                                    <TwoFaModal
                                        {..._2fa}
                                        onClose={() =>
                                            stageControl.setStage(
                                                SignInStage.SIMPLE_SIGN_IN,
                                            )
                                        }
                                    />
                                )}
                            </>
                        );

                    case SignInStage.SUCCESS:
                    case SignInStage.FINISH:
                        return (
                            <Loader
                                cubesColor={accentColor}
                                bgColor={textColor}
                            />
                        );
                }
            })()}
        </>
    );
});
