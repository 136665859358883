import React from "react";
import urljoin from "url-join";
import { LocalizedNavLink } from "components/language-provider";
import { NavHashLink } from "react-router-hash-link";
import { ContainerLang, Navigation, DesktopOnly } from "./styled";
import { LanguageSwitcher } from "components/components-common/language/language-switcher";
import { accentColor } from "../../../../services/styled-components/variables";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

interface IProps {
    color: string;
    menuItemsData: Array<{
        linkUrl: string;
        linkText: any;
    }>;
}

export const Nav = React.memo((props: IProps) => {
    const [__] = useTranslation();
    const routeLocation = useLocation().pathname;

    return (
        <Navigation color={props.color}>
            {props.menuItemsData.map((el, i) => (
                <DesktopOnly key={i}>
                    <LocalizedNavLink to={el.linkUrl}>
                        {el.linkText}
                    </LocalizedNavLink>
                </DesktopOnly>
            ))}
            <DesktopOnly>
                <NavHashLink
                    smooth
                    to={urljoin("/", routeLocation, "/", "#contacts")}
                >
                    {__("Контакты")}
                </NavHashLink>
            </DesktopOnly>
            <ContainerLang color={props.color}>
                <LanguageSwitcher
                    textColor="#000"
                    bgColor="#FFF"
                    accentColor="#000"
                    textColorHover={accentColor}
                    screen="desktop"
                />
            </ContainerLang>
        </Navigation>
    );
});
