import { InputHTMLAttributes } from "react";
import { Info } from "../../views/landing/auth/components/info";
import {
    FormError,
    InputWrapper,
    Required,
    Border,
    FormItemInputContent,
    FormItemTextareaContent,
} from "./styled";

export interface ICustomInput extends InputHTMLAttributes<HTMLInputElement> {
    touched?: boolean | undefined;
    errors?: string | undefined;
    warnings?: boolean;
    required?: boolean;
    forOther?: boolean;
    hint?: string;
    className?: string;
}

export const CustomInput = ({
    touched,
    errors,
    warnings,
    required,
    hint,
    forOther,
    className,
    ...props
}: ICustomInput) => {
    let criticalityLevel = 0;
    if (touched && !!errors && warnings) {
        criticalityLevel = 1;
    } else if (touched && !!errors) {
        criticalityLevel = 2;
    }
    return (
        <InputWrapper forOther={forOther} className={className}>
            {forOther ? (
                <FormItemTextareaContent
                    criticalityLevel={criticalityLevel}
                    autoFocus={props.autoFocus}
                    name={props.name}
                    id={props.id}
                    placeholder={props.placeholder}
                    onChange={(e: any) => props.onChange && props.onChange(e)}
                    onBlur={(e: any) => props.onBlur && props.onBlur(e)}
                    value={props.value}
                />
            ) : (
                <FormItemInputContent
                    criticalityLevel={criticalityLevel}
                    {...props}
                />
            )}
            <Border />
            {required && <Required>*</Required>}
            {hint && <Info hint={hint} />}
            {!!criticalityLevel && (
                <FormError isWarning={warnings}>{errors}</FormError>
            )}
        </InputWrapper>
    );
};
