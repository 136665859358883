import { useLazyQuery } from "@apollo/client";
import {
    CheckEmailFree,
    CheckEmailFreeVariables,
} from "gql/types/operation-result-types";
import CHECK_EMAIL_FREE from "./check-email-free.gql";
import { useTranslation } from "react-i18next";

export function useCheckEmailFree() {
    const [__] = useTranslation();
    const [getCheckEmail] = useLazyQuery<
        CheckEmailFree,
        CheckEmailFreeVariables
    >(CHECK_EMAIL_FREE);

    const checkEmailIsFree = async (email: string) => {
        const emailIsFree = await getCheckEmail({
            variables: {
                email,
            },
        });
        if (emailIsFree.data?.checkEmail === false) {
            return __(
                "Такой email уже зарегистрирован. Попробуйте использовать другой.",
            );
        }
    };

    return checkEmailIsFree;
}
