import styled, { css } from "styled-components";
import { LocalizedLink, LocalizedNavLink } from "components/language-provider";
import { media } from "services/styled-components/variables";
import {
    accentColor,
    backgroundColor,
    gradientColor,
    secondTextColor,
} from "../services/styled-components/variables";
import Quote from "../assets/images/quote.svg";
import { FormError } from "../components/custom-input/styled";

/* stylelint-disable */

export const ContentWrapper = styled.div`
    min-height: 100vh;
    padding-top: 9.2rem;
    overflow: hidden;
    ${media.mdMax`
        padding-top: 5rem;
    `}
`;

export const Logo = styled(LocalizedLink)`
    display: block;
    position: relative;
    z-index: 2;

    & img {
        max-width: 12rem;
    }

    ${media.smMax`
        padding: 1.2rem 0;
        & img {
            width: 9rem;
        }
    `}
`;

export const StateOfBtn = css`
    transition: all 0.2s;
    &:hover {
        background: linear-gradient(
            90deg,
            ${accentColor} 0%,
            ${gradientColor} 100%
        );
    }

    &:active {
        background: ${gradientColor};
    }
`;

export const StateOfLink = css`
    transition: all 0.2s;

    &:hover {
        color: ${accentColor};
        border-bottom: 2px solid ${accentColor};
    }

    &:active {
        color: ${gradientColor};
        border-bottom: 2px solid ${gradientColor};
    }
`;

interface IBlock {
    allowInMobile?: boolean;
    forbiddenInDesktop?: boolean;
}

export const Block = styled.div<IBlock>`
    color: rgb(255, 255, 255);
    font-size: 1.6rem;
    display: ${({ forbiddenInDesktop }: IBlock) =>
        forbiddenInDesktop ? "none" : "flex"};
    flex-direction: column;
    align-items: start;
    gap: 1rem;
    ${media.mdMax`
        display: ${({ allowInMobile }: IBlock) =>
            allowInMobile ? "flex" : "none"};
        flex-direction: column;
        ${Logo} {
           margin: 0 auto;
        }
        gap: 0;
    `}
`;
interface IColumnHeader {
    align?: string;
}

export const ColumnHeader = styled.p<IColumnHeader>`
    font-size: 1.4rem;
    opacity: 0.5;
    text-transform: uppercase;
    width: 100%;
    text-align: ${({ align }) => align || "center"};
    ${media.mdMax`
        position: relative;
    `}
`;

export const FooterElement = styled(LocalizedLink)`
    display: flex;
    align-items: center;
    gap: 15px;
    line-height: 22px;
    color: rgb(255, 255, 255);
    cursor: pointer;
    ${StateOfLink}

    &:hover {
        color: ${accentColor};
        svg {
            fill: ${accentColor};
        }
    }
`;

export const Title = styled.div<{
    color?: string;
}>`
    font-weight: 700;
    font-size: 5rem;
    line-height: 120%;
    color: ${props => props.color || secondTextColor};
    text-transform: uppercase;
    text-align: center;
    margin: 0;

    ${media.lgMax`
        font-size: 2.6em;
    `}
`;

export const WrapperPlainText = styled.div`
    padding: 0 20% 15rem 20%;
    color: ${secondTextColor};
    ${media.mdMax`
        padding: 0 6% 5rem 6%;
    `}
    h1 {
        font-size: 7rem;
        font-weight: 700;
        margin: 11.7rem auto 7rem;
        line-height: 120%;
        width: 100%;
        ${media.mdMax`
            font-size: 3rem;
            margin: 4.4rem 0 4.8rem 0;
            width: 100%;
        `}
    }
    h2,
    b {
        font-size: 6rem;
        font-weight: 700;
        margin: 0 0 3rem 0;
        line-height: 120%;
        ${media.mdMax`
            font-size: 2.6rem;
            margin-bottom: 1.6rem;
        `}
    }
    h3 {
        font-size: 5rem;
        font-weight: 700;
        line-height: 120%;
        margin: 7rem 0 3rem 0;
        ${media.mdMax`
            font-size: 2rem;
            margin: 3.6rem 0 1.6rem 0;
        `}
    }
    h4 {
        font-weight: 700;
        font-size: 4rem;
        line-height: 120%;
        margin: 7rem 0 3rem 0;
        ${media.mdMax`
            font-size: 1.8rem;
            margin: 3.6rem 0 1.6rem 0;
        `}
    }
    h5 {
        font-weight: 700;
        font-size: 3rem;
        line-height: 120%;
        margin: 7rem 0 3rem 0;
        ${media.mdMax`
            font-size: 1.6rem;
            margin: 3.6rem 0 1.6rem 0;
        `}
    }
    h6 {
        font-weight: 700;
        font-size: 2rem;
        line-height: 120%;
        margin: 7rem 0 3rem 0;
        ${media.mdMax`
            font-size: 1.4rem;
            margin: 3.6rem 0 1.6rem 0;
        `}
    }
    img {
        border-radius: 3rem;
        width: 100%;
        margin: 3rem 0 2rem 0;
        display: block;
        ${media.mdMax`
            margin: 2rem 0 1.2rem 0;
        `}
    }
    span {
        font-weight: 400;
        font-size: 1.4rem;
        line-height: 120%;

        ${media.mdMax`
            font-size: 1.2rem;
        `}
    }
    p {
        font-size: 1.8rem;
        font-weight: 400;
        line-height: 160%;
        margin: 1.6rem 0;
        ${media.mdMax`
            font-size: 1.4rem;
        `}
    }
    blockquote {
        position: relative;
        width: 100%;
        box-sizing: border-box;
        margin: 3rem 0;
        background: linear-gradient(
            90deg,
            ${accentColor} 0%,
            ${gradientColor} 100%
        );
        border-radius: 3rem;
        font-weight: 700;
        font-size: 2rem;
        line-height: 160%;
        color: #ffffff;
        padding: 2rem 3rem 3rem 9rem;
        ${media.mdMax`
        font-size: 1.4rem;
        padding: 1.2rem 1.6rem 1.2rem 5rem;
        margin: 2rem 0;
    `}
        &::before {
            display: block;
            content: "";
            position: absolute;
            width: 4.2rem;
            height: 2.4rem;
            left: 3rem;
            top: 2.9rem;
            background-image: url(${Quote});
            ${media.mdMax`
                width: 2.6rem;
                height: 1.4rem;
                left: 1.6rem;
                top: 1.6rem;
                background-size: cover;
            `}
        }
    }
    ol,
    ul:not(.slick-dots) {
        padding-left: 2.7rem;
        font-weight: 400;
        font-size: 1.8rem;
        line-height: 160%;
        ${media.mdMax`
            font-size: 1.4rem;
            padding-left: 2.4rem;
        `};
    }
    ul {
        list-style: disc;
    }
    li::marker {
        color: ${accentColor};
        font-weight: 700;
    }
    table {
        font-weight: 400;
        font-size: 1.8rem;
        line-height: 160%;
        border-collapse: collapse;
        width: 100%;
        ${media.mdMax`
            font-size: 1.4rem;
        `};
    }
    th {
        font-weight: 700;
        border-top: 0.1rem solid rgba(24, 24, 24, 0.1);
        border-bottom: 0.1rem solid rgba(24, 24, 24, 0.1);
        padding: 1.6rem 0;
        text-align: left;
        ${media.mdMax`
            padding: 1.2rem 0;
        `};
    }
    td {
        border-bottom: 0.1rem solid rgba(24, 24, 24, 0.1);
        padding: 1.6rem 0;
        width: 14.8rem;
        ${media.mdMax`
            padding: 1.2rem 0;
        `};
    }
`;

export const Form = styled.form`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.25em;
    font-size: 1.6em;
    line-height: 120%;
    color: ${secondTextColor};

    ${media.lgMax`
        font-size: 1.2em;
        gap: 1.3em;
    `}

    ${media.ltMax`
        gap: 0.5rem;
    `}

    & input {
        &:focus-visible {
            outline: ${accentColor} 1px solid;
        }

        &:active,
        &:hover,
        &:focus {
            border: ${accentColor} 1px solid;
        }
    }

    ${FormError} {
        color: ${secondTextColor};
    }
`;

export const ButtonStyle = css`
    border-radius: 3.75em;
    padding: 0.9em 0.9em 0.9em 1.185em;
    border: solid 1px #a7c3da;
    box-sizing: border-box;
    background-color: #fff;
    color: ${accentColor};
    text-transform: uppercase;
    transition: all 0.2s;
    font-weight: 700;
    width: 38.7rem;
    margin-top: 1.25em;
    cursor: pointer;
    text-align: center;

    &:active,
    &:hover,
    &:focus {
        background: ${backgroundColor};
        color: #fff;
        border: solid 1px ${backgroundColor};
    }

    ${media.lgMax`
        padding: 1.08em 1.08em 1.08em 1.8em;
    `}

    ${media.mdMax`
        width: 28.8rem;
    `}

    ${media.xsMax`
        width: 100%;
    `}
`;

export const SendButton = styled.button`
    ${ButtonStyle}
`;

export const LinkButton = styled(LocalizedNavLink)`
    ${ButtonStyle}
`;

export const ArticleWrapper = styled.div`
    color: ${secondTextColor};
    padding: 0 20% 15rem 20%;
    ${media.mdMax`
        padding: 0 6% 5rem 6%;
    `}
    ${media.mdMax`
        padding: 0 6% 5rem 6%;
    `}
`;

export const ArticleTitle = styled.h1`
    height: 15rem;
    background: ${backgroundColor};
    margin: 0;
    margin-bottom: 7rem;
    padding: 0 1rem;
    font-size: calc((100vw - 32rem) / (1920 - 320) * (70 - 30) + 3rem);
    font-weight: 700;
    line-height: 120%;
    letter-spacing: 0.03em;
    color: ${accentColor};
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    ${media.smMax`
        font-weight: 400;
        height: 10.8rem;
    `}
`;
