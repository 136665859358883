import styled, { DefaultTheme, css } from "styled-components";
import {
    accentColor,
    backgroundColor,
    gradientColor,
} from "../../../../services/styled-components/variables";
import { media } from "services/styled-components/variables";
import wrapperBgDesktopSrc from "views/irregular-landings/57/assets/images/first-screen-bg-desktop.png";
import wrapperBgMobileRocketTextSrc from "views/irregular-landings/57/assets/images/first-screen-background-mobile-rocket-text.png";
import wrapperBgMobileAdornmentSrc from "views/irregular-landings/57/assets/images/first-screen-background-mobile-adornment.png";
import basketballSrc from "../../../../assets/images/icons/icon1.svg";
import fifaSrc from "../../../../assets/images/icons/icon2.svg";
import tennisSrc from "../../../../assets/images/icons/icon3.svg";
import hockeySrc from "../../../../assets/images/icons/icon4.svg";
import volleyballSrc from "../../../../assets/images/icons/icon6.svg";
import { rtl } from "services/styled-components/rtl";

const gradient = `linear-gradient(90deg, ${accentColor} 0%, ${gradientColor} 100%)`;

interface IWithTheme {
    theme: DefaultTheme;
}

export const Wrapper = styled.div`
    position: relative;
    height: 44.8rem;
    color: #fff;
    background-image: url(${wrapperBgMobileRocketTextSrc}),
        url(${wrapperBgMobileAdornmentSrc}), ${gradient};
    background-size: auto 30.4rem, auto 13.8rem, 100%;
    background-position: calc(-9.2rem + 50%) 1rem, right 35.6rem, 0;
    background-repeat: no-repeat;

    ${media.md`
        height: 83.7rem;
        background-image: url(${wrapperBgDesktopSrc}), ${gradient};
        background-size: 192rem, 100%;
        background-position: center 9rem, 0;
     `}
`;

export const TextBlock = styled.div`
    position: absolute;
    top: 28rem;
    right: 1rem;
    max-width: 40rem;

    ${props =>
        props.theme.dir === "rtl"
            ? css`
                  right: calc(40% - 12rem);
              `
            : css`
                  left: calc(60% - 18rem);
              `}

    ${media.sm`
        max-width: 47rem;

        ${(props: IWithTheme) =>
            props.theme.dir !== "rtl" &&
            css`
                left: calc(60% - 24rem);
            `}
    `}

    ${media.md`
        top: 40rem;
        max-width: 64rem;

        ${(props: IWithTheme) =>
            props.theme.dir === "rtl"
                ? css`
                      right: calc(50% + 3rem);
                  `
                : css`
                      left: calc(50% - 45rem);
                  `}
    `}

    ${media.lg`
        ${(props: IWithTheme) =>
            props.theme.dir === "rtl"
                ? css`
                      right: calc(50% + 12rem);
                  `
                : css`
                      left: calc(50% - 60rem);
                  `}
    `}
`;

export const Title = styled.h1`
    font-weight: 700;
    font-size: 7em;
    color: #fff;
    margin: 0 0 0.6em;
    line-height: 120%;

    ${media.lgMax`
        font-size: 3em;
    `}
`;

const buttonStyle = css`
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: rgba(32, 32, 34, 0.15) 0 1em 1em;
    border-radius: 3.3em;
    transition: all 0.2s;
    text-align: center;
    &:hover {
        background: ${backgroundColor};
        color: #fff;
    }

    ${media.lgMax`
        padding: 1rem 1.4rem 1.3rem;
        font-size: 1.4rem;
        line-height: normal;
    `}
`;

export const ButtonsBlock = styled.div`
    font-size: 1.8em;
    gap: 1.44em;
    display: flex;
    font-weight: 700;

    ${media.smMax`
        flex-direction: column;
        max-width: 16.7em;
    `}

    & a:first-child {
        ${buttonStyle}
        width: fit-content;
        background: #fff;
        padding: 1em 1.67em;
        color: ${accentColor};
        text-wrap: nowrap;
    }

    .downloadButton {
        ${buttonStyle}
        display: none;
        font-size: 0.89em;
        background: rgba(255, 255, 255, 0.1);
        padding: 0.44em 1.4em;
        color: #ffffff;
        line-height: 1.2;
        border: 1px solid #ffffff;

        ${media.sm`
            display: flex;
        `}
    }

    .hashButton {
        align-self: center;
        border-bottom: 2px solid #fff;
        color: #fff;
        position: relative;
        margin-left: 1.34em;

        ${media.smMax`
            display: none;
        `}

        &::after {
            content: "\\2193";
            font-size: 1.6em;
            line-height: 1;
            width: 1.4em;
            height: 1.4em;
            border: 1px solid rgb(255, 255, 255);
            border-radius: 50%;
            position: absolute;
            display: flex;
            justify-content: center;
            top: 0;
            ${rtl`
                right: -2em;
            `};
        }

        &:hover {
            color: ${backgroundColor};
            border-bottom: 2px solid ${backgroundColor};

            &::after {
                border-color: ${backgroundColor};
            }
        }
    }
`;

export const RunningLineWrapper = styled.div`
    display: flex;
    align-items: center;
    background: ${backgroundColor};
    overflow: hidden;
`;

export const RunningLine = styled.ul`
    font-size: 2em;
    display: flex;
    justify-content: space-between;
    color: #fff;
    gap: 4.5em;
    white-space: nowrap;
    padding: 1.2em 0;
    animation: marquee 50s infinite linear;

    ${media.mdMax`
        font-size: 1.4em;
        padding: 1.7em 0;
    `}

    @keyframes marquee {
        0% {
            transform: translateX(
                ${props => (props.theme.dir === "rtl" ? "1%" : "1%")}
            );
        }
        100% {
            transform: translateX(
                ${props => (props.theme.dir === "rtl" ? "52.5%" : "-50%")}
            );
        }
    }
`;

export const RunningLineItem = styled.li`
    text-transform: uppercase;
    display: flex;
    align-items: center;
    position: relative;

    &::after {
        content: "";
        width: 1.5em;
        height: 1.5em;
        position: absolute;
        ${rtl`right: -3em;`};
        background-repeat: no-repeat;
        background-size: contain;
    }

    &:nth-child(6n + 1) {
        &::after {
            background-image: url(${basketballSrc});
        }
    }
    &:nth-child(6n + 2) {
        &::after {
            background-image: url(${fifaSrc});
            width: 2.2em;
            height: 1em;
            ${rtl`right: -3.3em;`};
        }
    }
    &:nth-child(6n + 3) {
        &::after {
            background-image: url(${tennisSrc});
        }
    }
    &:nth-child(6n + 4) {
        &::after {
            background-image: url(${hockeySrc});
        }
    }
    &:nth-child(6n + 5) {
        &::after {
            background-image: url(${tennisSrc});
        }
    }
    &:nth-child(6n + 6) {
        &::after {
            background-image: url(${volleyballSrc});
        }
    }
`;
